@import '../../../../../theme/variables.scss';

.personal_details {
    .about {
        margin-top: 10px;
    }

    .actions_main {
        margin-top: 10px;
    }

    .personal_details__container {
        .groups_container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .group_card {
                background-color: #fff;
                border-radius: 10px;
                padding: 15px;

                h3 {
                    font-size: 20px;
                    font-weight: 600;
                    color: $primary-color;
                }

                p {
                    font-size: 16px;
                    color: $gray-color;
                }

                .ant-btn {
                    margin-top: 10px;
                    padding: 0;
                }
            }
        }
    }
}

.view_group_drawer {
    .goup_view_header {
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        h1 {
            font-size: 24px;
            font-weight: 600;
            color: $primary-color;
        }

        p {
            margin-top: 10px;
            font-size: 16px;
            color: $gray-color;
        }
    }

    .group_vew_body {
        .group_members_container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .ant-divider {
                margin: 0;
            }

            .group_member_card {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .group_member_card__header {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    .left {
                        width: 50px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }

                    .right {
                        .actions {
                            display: flex;
                            gap: 10px;
                            margin-top: 10px;

                            .accept {
                                color: $light-1-color;
                                border: 1px solid $light-1-color;
                            }
                        }

                        h3 {
                            font-size: 18px;
                            font-weight: 600;
                            color: $primary-color;
                            margin: 0;
                        }

                        p {
                            font-size: 16px;
                            color: $gray-color;
                            margin: 5px 0;
                        }
                    }
                }
            }
        }
    }
}