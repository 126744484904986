@import '../../../../theme/variables.scss';

.custom_section_left {
    padding: 30px 0 30px $content_padding !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $background-color;

    @media (max-width: 1200px) {
        padding: 30px 0 30px $content_padding_tablet !important;
    }

    @media (max-width: 768px) {
        padding: 30px 0 30px $content_padding_tablet !important;
    }

    @media (max-width: 576px) {
        padding: 30px $content_padding_mobile !important;
        flex-direction: column;
    }

    .left {
        width: 50%;

        .custom_title {
            color: $primary-color;

            .green {
                color: $light-1-color;
            }

            .light_green {
                color: $primary-color-light;
            }
        }

        ul {
            margin: 40px 0;

            li {
                list-style: none;

                p {
                    display: flex;
                    align-items: center;
                    font-size: 25px;
                    font-weight: 600;
                    color: $primary-color;

                    &:before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        background-color: $primary-color-light;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }
            }
        }

        .sub_text {
            font-size: 20px;
            font-weight: 600;
            color: $primary-color;
            margin-bottom: 40px;
        }

        @media (max-width: 1280px) {
            ul {
                margin: 30px 0;

                li {
                    p {
                        font-size: 20px;
                    }
                }
            }

            .sub_text {
                font-size: 18px;
                margin-bottom: 30px;
            }
        }

        @media (max-width: 1024px) {
            width: 60%;

            ul {
                margin: 20px 0;

                li {
                    line-height: 1;

                    p {
                        font-size: 18px;
                    }
                }
            }

            .sub_text {
                font-size: 16px;
                margin-bottom: 20px;
            }
        }

        @media (max-width: 768px) {
            width: 70%;

            ul {
                margin: 20px 0;

                li {
                    p {
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }

            .sub_text {
                font-size: 14px;
                margin-bottom: 20px;
                font-weight: 500;
            }
        }

        @media (max-width: 576px) {
            width: 100%;

            ul {
                margin: 20px 0;

                li {
                    p {
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }

            .sub_text {
                font-size: 14px;
                margin-bottom: 20px;
                font-weight: 500;
            }
        }

        .btns {
            display: flex;
            gap: 10px;

            .anticon {
                font-size: 20px;

                @media (max-width: 768px) {
                    font-size: 15px;
                }
            }
        }
    }

    .right {
        max-height: 665px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 0px 13.6px 7px rgba(0, 0, 0, 0.05);

        @media (max-width: 1024px) {
            width: 50%;
        }

        @media (max-width: 576px) {
            margin-top: 20px;
            width: 100%;
            max-height: 400px;
            border-top-left-radius: 0;
            border-top-right-radius: 20px;
            border-bottom-left-radius: 0;
            border-top-left-radius: 20px;
        }

        img {
            width: 100%;
            display: block;
        }
    }
}