@import './variables.scss';

.header_1 {
    font-size: 40px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    color: $primary-color;
    line-height: 120%;

    .light {
        color: $primary-color-light;
    }

    @media (max-width: 768px) {
        font-size: 30px;
    }
}

.heder_2 {
    font-size: 28px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: $primary-color;

    @media (max-width: 768px) {
        font-size: 24px;
    }
}

.header_3 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: $primary-color;

    @media (max-width: 768px) {
        font-size: 24px;
    }
}

.header_4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: $primary-color;

    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.paragraph {
    font-size: 18px;
    line-height: 1.5;
    color: $dark-color;

    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.default_anchor {
    color: $light-2-color;
    text-decoration: none;

    &:hover {
        color: $primary-color-light;
    }
}

.paragraph {
    font-size: 18px;
    line-height: 1.5;
    color: $dark-color;

    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.ql-container {
    .ql-editor {
        padding: 0;
        background-color: none;
        all: unset;

        * {
            color: $dark-color !important;
            line-height: 1.6;
        }

        p {
            font-size: 16px;
            line-height: 1.5;
            color: $dark-color;

            @media (max-width: 768px) {
                font-size: 14px;
            }
        }

        ul,
        ol {
            margin-top: 10px;

            li {
                font-size: 16px;
                line-height: 1.5;
                color: $dark-color;
                margin-bottom: 5px;

                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: 700;
            color: $dark-color;
            margin: 20px 0 10px 0;
        }

        h1 {
            font-size: 24px;
        }

        h2 {
            font-size: 22px;
        }

        h3 {
            font-size: 20px;
        }

        h4 {
            font-size: 18px;
        }

        h5 {
            font-size: 16px;
        }

        h6 {
            font-size: 14px;
        }
    }

    .ql-tooltip {
        display: none;
    }
}