@import './variables.scss';

.main__contaier {
    min-height: 100vh;

    .body_content {
        padding: 0 $content_padding;
    }

    .hero__background__overlay {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 300px;
        background: linear-gradient(180deg, #fff, #ffffff00);
        z-index: -1;

        @media (max-width: 768px) {
            display: none;
        }
    }

    .hero {
        text-align: center;
        position: relative;
        background-color: transparent;

        .hero__background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -2;

            .lazy-load-image-background {
                width: 100%;
            }

            img {
                width: 100%;
                object-fit: cover;
                transform: translateY(-18%);

                @media (max-width: 768px) {
                    transform: translateY(0);
                }

                @media (max-width: 576px) {
                    transform: translateY(0);
                    height: 500px;
                    object-fit: cover;
                }
            }
        }
    }

    .custom-shape-divider-top-1724082403 {
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-top-1724082403 svg {
        position: relative;
        display: block;
        width: calc(163% + 1.3px);
        height: 180px;

        @media (max-width: 768px) {
            height: 100px;
        }

        @media (max-width: 576px) {
            height: 70px;
        }
    }

    .custom-shape-divider-top-1724082403 .shape-fill {
        fill: $background-color;
    }

    .hero__search__button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        @media (max-width: 768px) {
            margin-top: 10px;
        }

        .ant-select {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 500px !important;

            .search__input {
                width: 500px;
                height: 50px;
                border-radius: 100px;
                padding: 0 20px;
                border: none;
                background: rgba(255, 255, 255, 0.6);
                color: $gray-color;
                font-size: 16px;
                font-weight: 500;

                @media (max-width: 1200px) {
                    width: 320px;
                }
            }

            @media (max-width: 1200px) {
                width: 320px !important;
            }

            @media (max-width: 768px) {
                width: 320px;

                .search__input {
                    height: 40px;
                    border-radius: 100px;
                    padding: 0 20px;
                    border: none;
                    background: rgba(255, 255, 255, 0.6);
                    color: $gray-color;
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            @media (max-width: 576px) {
                width: 220px !important;

                .search__input {
                    width: 220px;
                }
            }
        }

        .serach_btn {
            color: $primary-color;
            font-size: 16px;
            font-weight: 600;
            background-color: $primary-color-light;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 24px 30px;

            &:hover {
                background-color: $primary-color !important;
                color: $white-color;
            }

            @media (max-width: 768px) {
                padding: 20px 20px;
                font-size: 14px;
            }

            @media (max-width: 576px) {
                padding: 19px 20px;
                font-size: 12px;

                .ant-btn-icon {
                    display: none;
                }
            }
        }
    }
}


// google translate
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

.g_translate_container {
    display: none;
}

body {
    background-color: $background-color;
    position: static !important;
}

#root {
    overflow: hidden;
}

.custom_primary_btn {
    color: $primary-color;
    font-size: 15px;
    font-weight: 600;
    background-color: $primary-color-light;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 25px;
    border: none;

    &:hover {
        background-color: $primary-color !important;
        color: $white-color !important;
    }

    @media (max-width: 768px) {
        padding: 20px 20px;
        font-size: 14px;
    }
}

.custom_primary_btn_2 {
    color: $primary-color;
    font-size: 16px;
    font-weight: 500;
    background-color: $primary-color-light;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 21px 20px;
    border: none;

    &:hover {
        background-color: $primary-color !important;
        color: $white-color !important;
    }

    @media (max-width: 768px) {
        padding: 20px 20px;
        font-size: 14px;
    }
}

.primary_btn_default {
    font-size: 16px;
    font-weight: 500;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
    color: $light-1-color;
    border-color: $light-1-color;
    background-color: transparent;

    &:hover {
        color: $primary-color;
        font-size: 14px;
    }
}

.custom_primary_btn_link {
    color: $primary-color;
    font-size: 15px;
    font-weight: 600;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    padding: 25px 5px;

    &:hover {
        color: $light-2-color;
    }

    @media (max-width: 768px) {
        padding: 20px 5px;
        font-size: 14px;
    }
}

.custom_primary_btn_large {
    color: $primary-color;
    font-size: 15px;
    font-weight: 600;
    background-color: $primary-color-light;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 25px;

    &:hover {
        background-color: $primary-color !important;
        color: $white-color;
    }
}

.custom_primary_btn_large_dark {
    color: $white-color;
    font-size: 15px;
    font-weight: 600;
    background-color: $primary-color;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 25px;

    &:hover {
        background-color: #1c6095 !important;
        color: $white-color;
    }
}

.custom_secondery_btn {
    color: $primary-color;
    font-size: 15px;
    font-weight: 600;
    background-color: $primary-color-light;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: $primary-color !important;
        color: $white-color;
    }
}

.ant-select-item-option-content {
    display: flex;
    align-items: center;
    gap: 5px;
}

.section_main {
    padding: 30px $content_padding;
    background-color: $background-color;

    .section_title {
        font-size: 64px;
        font-weight: 700;
        color: $primary-color;

        @media (max-width: 1280px) {
            font-size: 48px;
        }

        @media (max-width: 768px) {
            font-size: 42px;
            margin-bottom: 0;
        }
    }

    .align_start {
        text-align: start;
        margin-top: 10px;
    }

    .section_subtitle {
        font-size: clamp(16px, 20vw, 24px);
        font-weight: 400;
        color: $dark-color;
    }

    @media (max-width: 1200px) {
        padding: 20px $content_padding_tablet;
    }

    @media (max-width: 768px) {
        padding: 20px $content_padding_tablet;
    }

    @media (max-width: 576px) {
        padding: 20px $content_padding_mobile;
    }
}

.destination__card_loading {
    min-height: 300px;
    // max-width: 325px;
    border-radius: 20px !important;
    border: none !important;
    box-shadow: 0px 0px 4px 0px rgba(205, 205, 205, 0.115);

    @media (max-width: 600px) {
        max-width: 100%;
    }
}


.destination__card {
    min-height: 300px;
    // max-width: 325px;
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.115);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        transform: scale(1.03) !important;
    }

    .background__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60%;
        background: linear-gradient(180deg, #ffffffda, #ffffff00);
        z-index: 5;
    }

    .destination__card__content {
        z-index: 10;
        color: $primary-color;
        // padding: 0px;

        h2 {
            font-size: 28px;
            font-weight: 600;
            // backdrop-filter: blur(10px);
            // width: fit-content;
            // padding: 10px 20px;
            // background-color: #ffffff7d;
            // border-radius: 10px 0 10px 0;
        }

        p {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 27px;
            font-weight: 600;
            color: $primary-color;
        }

        h3 {
            margin: 0;
            font-size: clamp(0.8rem, -1.5rem + 8vw, 1.2rem);
        }
    }

    @media (max-width: 768px) {
        max-width: 100%;
        min-height: 250px;

        .destination__card__content {
            h2 {
                font-size: 24px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

.empty_cards {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    font-size: 28px;
    font-weight: 600;
    border: 1px solid #0000000f;
    margin-top: 20px;
    border-radius: 20px;
}


// Package Card
.package__card {
    min-height: 500px;
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.115);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        transform: scale(1.03) !important;
    }

    @media (max-width: 1500px) {
        min-height: 565px;
    }

    @media (max-width: 1280px) {
        min-height: 500px;
    }

    @media (max-width: 1024px) {
        min-height: 620px;
    }

    @media (max-width: 600px) {
        min-height: 100%;
    }

    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .destination__card__content {
        z-index: 10;
        color: $primary-color;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h2 {
            font-size: clamp(1.5rem, -1.5rem + 8vw, 1.8rem);
            font-weight: 600;
            margin-bottom: 14px;
        }

        .ant-tag {
            font-size: 12px;
            background-color: transparent;
            color: $light-1-color;
            border: 1px solid $light-1-color;
            padding: 2px 10px;
            margin-bottom: 5px;
        }

        .ql-editor {
            margin: 0;
            padding: 0;
            font-size: 14px;
            margin-top: 8px;
        }

        .ql-tooltip {
            display: none;
        }

        .package__card__footer {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            align-items: end;

            @media (max-width: 1280px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
            }

            .left {
                display: flex;
                align-items: center;
                gap: 5px;

                @media (max-width: 1500px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                @media (max-width: 1280px) {
                    flex-direction: row;
                    align-items: center;
                }

                .rating {
                    background-color: $primary-color;
                    color: $white-color;
                    padding: 7px 14px;
                    border-radius: 5px;
                }

                .discount {
                    background-color: $red_orange_color;
                    color: $white-color;
                    padding: 7px 14px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;

                    gap: 5px;

                    h3 {
                        font-size: 16px;
                        margin: 0;
                    }
                }
            }

            .price {
                display: inline;

                .price__from {
                    font-size: clamp(1rem, -1.5rem + 8vw, 1.2rem);
                    font-weight: 600;
                    color: $primary-color;
                    margin: 0;
                }

                .amount,
                .unit {
                    font-size: clamp(1.5rem, -1.5rem + 8vw, 1.8rem);
                    font-weight: 600;
                    color: $primary-color;
                }

                .unit {
                    margin-left: 2px;
                }
            }
        }
    }
}

.package__card_loading {
    min-height: 300px;
    border-radius: 20px !important;
    border: none !important;
    box-shadow: 0px 0px 4px 0px rgba(205, 205, 205, 0.115);
}

.breadcrumb_pages {
    .breadcrumb__item {
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
        transition: all 0.3s ease;

        &:hover {
            color: $primary-color;
        }
    }

    .last {
        color: $light-1-color;
    }
}

// Section Video Add
.section_main_video {
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    margin: 40px 0;
    height: 525px;

    @media (max-width: 768px) {
        height: 300px;
    }

    iframe {
        display: block;
        width: 100%;
        height: 100%;
    }
}