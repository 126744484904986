@import '../../../../../theme/variables.scss';

.personal_details {
    .about {
        margin-top: 10px;
    }

    .personal_details__container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .booking__card {
            background-color: #fff;
            padding: 15px 20px;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }

            h3 {
                font-size: 24px;
                font-weight: 500;
                color: $primary-color;
            }

            h4 {
                display: flex;
                gap: 10px;
                align-items: center;

                span,
                p {
                    font-size: 16px;
                    font-weight: 400;
                }

                span {
                    color: $gray-color;
                }
            }
        }


        .profile_pic {
            justify-content: start;
            gap: 10px;
        }
    }

    .red {
        color: red;
    }

    .green {
        color: green;
    }

    .yellow {
        color: #ffae1f;
    }
}