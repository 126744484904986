@import '../../../theme/variables.scss';

.footer {
    padding: $navbar_padding;
    background-color: $background-color;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 20px;
    }

    @media (max-width: 576px) {
        padding: 20px 10px;
    }

    .footer__top {
        display: flex;
        justify-content: space-between;
        padding: 50px 0 30px 0;
        gap: 80px;

        h1 {
            font-size: 22px;
            font-weight: 600;
            color: $primary-color;
            margin-bottom: 20px;
        }

        .description {
            flex: 1;

            .sltda_registration {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                @media (max-width: 768px) {
                    flex-direction: column;
                }

                img {
                    width: 80px;
                }

                div {
                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: $dark-gray-color;
                        padding: 0;
                        margin: 0;
                        line-height: 1.5;
                    }

                    span {
                        font-size: 14px;
                        font-weight: 600;
                        color: $primary-color;
                        padding: 0;
                        line-height: 1.5;
                    }

                    @media (max-width: 768px) {
                        text-align: center;
                        padding: 0 20px;
                    }
                }
            }

            img {
                width: 200px;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                color: $dark-gray-color;
                margin-top: 20px;
                line-height: 1.5;
            }
        }

        .social__links {
            margin-top: 20px;
            display: flex;
            gap: 20px;

            a {
                color: $dark-color;
                font-size: 25px;
                transition: all 0.3s ease;

                &:hover {
                    color: $light_2_color;
                }
            }
        }

        .footer__links {
            margin-top: 20px;
            flex: 1.6;
            display: flex;
            align-items: start;
            justify-content: space-between;

            ul {
                padding: 0;
            }

            li {
                list-style: none;
                font-size: 15px;
                font-weight: 500;
                color: $dark-gray-color;
                cursor: pointer;
                transition: all 0.3s ease;
                line-height: 2;

                a {
                    color: $dark-gray-color;
                    text-decoration: none;

                    &:hover {
                        color: $light_2_color;
                    }
                }
            }
        }

        .newsletter {
            flex: 1;
            margin-top: 20px;

            .subscribing_loader {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
            }

            .subscribing_success {
                margin: 0;
                margin-top: 10px;
                color: $success_color;
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .subscribing_error {
                margin: 0;
                margin-top: 10px;
                color: $danger_color;
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .ant-form-item {
                margin-bottom: 0;
            }

            .input {
                display: flex;

                .ant-btn {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

                .ant-input {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            p {
                font-size: 14px;
                font-weight: 400;
                color: $dark-gray-color;
                margin-top: 15px;
                line-height: 1.5;
            }
        }
    }

    .footer__bottom {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $light-gray-color;

        p {
            font-size: 14px;
            font-weight: 400;
        }

        a {
            color: $light-2-color;
            transition: all 0.3s ease;
            text-decoration: none;

            &:hover {
                color: $light_1_color;
            }
        }
    }

    /* Media queries for responsiveness */

    /* Large desktop screens */
    @media (max-width: 1200px) {
        .footer__top {
            gap: 50px;

            .description img {
                width: 200px;
            }

            .footer__links {
                gap: 40px;

                li {
                    font-size: 14px;
                }
            }

            .newsletter p {
                font-size: 13px;
            }
        }
    }

    /* Medium desktop screens */
    @media (max-width: 992px) {
        .footer__top {
            flex-direction: column;
            gap: 40px;

            h1 {
                text-align: center;
            }

            .description {
                text-align: center;

                img {
                    margin: 0 auto;
                    width: 180px;
                }
            }

            .social__links {
                justify-content: center;
            }

            .footer__links {
                flex-direction: column;
                align-items: center;
                gap: 20px;

                .footer__links__column {
                    align-items: center;

                    ul {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }

            .newsletter {
                text-align: center;

                .ant-space-compact {
                    flex-direction: column;

                    .ant-btn {
                        margin-top: 10px;
                        width: 100%;
                    }
                }
            }
        }
    }

    /* Tablet screens */
    @media (max-width: 768px) {
        .footer__top {
            padding: 40px 0;
            gap: 20px;

            h1 {
                font-size: 20px;
                text-align: center;
            }

            .social__links {
                width: 100%;
                justify-content: center;
            }

            .footer__links {
                gap: 15px;
                align-items: center;

                li {
                    a {
                        font-size: 15px;
                    }
                }
            }
        }

        .footer__bottom {
            flex-direction: column;
            text-align: center;
            gap: 5px;

            p {
                padding: 0;
                margin: 0;
            }
        }
    }
}