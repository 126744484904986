@import './variables.scss';

.attraction__card {
    min-height: 420px;
    width: 100%;
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.115);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 1500px) {
        min-height: 460px;
    }

    @media (max-width: 1280px) {
        min-height: 480px;
    }

    @media (max-width: 1024px) {
        min-height: 380px;
    }

    @media (max-width: 600px) {
        min-height: 100%;
    }


    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .destination__card__content {
        z-index: 10;
        color: $primary-color;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .ant-tag {
            font-size: 12px;
            background-color: transparent;
            color: $light-1-color;
            border: 1px solid $light-1-color;
            padding: 2px 10px;
            margin-bottom: 5px;
        }

        .ql-editor {
            margin: 0;
            padding: 0;
            font-size: 14px;
            margin-top: 8px;
        }

        .ql-tooltip {
            display: none;
        }

        .package__card__footer {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            align-items: end;

            @media (max-width: 1280px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
            }

            .left {
                display: flex;
                align-items: center;
                gap: 5px;

                @media (max-width: 1500px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                @media (max-width: 1280px) {
                    flex-direction: row;
                    align-items: center;
                }

                .rating {
                    background-color: $primary-color;
                    color: $white-color;
                    padding: 7px 14px;
                    border-radius: 5px;
                }

                .discount {
                    background-color: $red_orange_color;
                    color: $white-color;
                    padding: 7px 14px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;

                    gap: 5px;

                    h3 {
                        font-size: 16px;
                        margin: 0;
                    }
                }
            }

            .price {
                display: inline;

                .price__from {
                    font-size: clamp(1rem, -1.5rem + 8vw, 1.2rem);
                    font-weight: 600;
                    color: $primary-color;
                    margin: 0;
                }

                .amount,
                .unit {
                    font-size: clamp(1.5rem, -1.5rem + 8vw, 1.8rem);
                    font-weight: 600;
                    color: $primary-color;
                }

                .unit {
                    margin-left: 2px;
                }
            }
        }
    }
}

.attraction__card_loading {
    min-height: 420px;
    width: 100%;
    border-radius: 20px !important;
    border: none !important;
    box-shadow: 0px 0px 4px 0px rgba(205, 205, 205, 0.115);

    .ant-skeleton {
        width: 100%;
    }

    .ant-skeleton-image {
        width: 100% !important;
        height: 200px !important;
    }

    .ant-skeleton-button {
        margin: 10px 0;
        width: 100% !important;
    }


    @media (max-width: 1500px) {
        min-height: 460px;
    }

    @media (max-width: 1280px) {
        min-height: 480px;
    }

    @media (max-width: 1024px) {
        min-height: 380px;
    }

    @media (max-width: 600px) {
        min-height: 100%;
    }
}


// Tour Packages Main
.tour__packages__main_card {
    margin: 0;
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }

    .destination__card__content {
        display: flex;
        padding: 0;

        @media (max-width: 600px) {
            flex-direction: column;
        }

        .left {
            width: 600px;
            background-color: #dddddd85;

            @media (max-width: 1280px) {
                width: 550px;
            }

            @media (max-width: 600px) {
                width: 100%;
                height: 200px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .right {
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 15px;

            h1 {
                font-size: 25px;
                font-weight: 600;
                color: $primary-color;
                margin: 0;
                line-height: 120%;
            }

            .ql-editor {
                margin: 0;
                padding: 0;
                font-size: 14px;
                margin-top: 8px;
            }

            .tags {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .ant-tag {
                    font-size: 12px;
                    background-color: transparent;
                    color: $light-1-color;
                    border: 1px solid $light-1-color;
                    padding: 2px 10px;
                    margin-bottom: 5px;
                }
            }

            @media (max-width: 600px) {
                padding: 15px;

                h1 {
                    font-size: 20px;
                }

                .ql-editor {
                    margin: 0;
                    font-size: 13px;
                }

                .tags {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;
                }
            }

            .card_footer {
                display: flex;
                justify-content: space-between;
                align-items: end;

                @media (max-width: 600px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .details {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .item {
                        display: flex;
                        align-items: center;
                        font-size: 14px;

                        h3 {
                            font-size: 14px;
                            margin: 0;
                            padding: 0;
                            width: 80px;
                            font-weight: 700;
                        }

                        p {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }

                .max_details {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .item {
                        display: flex;
                        align-items: center;
                        justify-content: start;
                        font-size: 14px;
                        gap: 7px;

                        h3 {
                            font-size: 14px;
                            margin: 0;
                            padding: 0;
                            width: auto;
                            font-weight: 700;
                        }

                        p {
                            margin: 0;
                            padding: 0;
                        }
                    }

                    .hotel {
                        font-weight: 600;
                        font-size: 18px;
                        color: $gray-color;
                        margin-top: 15px;
                    }

                    .location {
                        font-weight: 400;
                        font-size: 14px;
                        color: $gray-color;
                    }
                }

                .price {
                    display: flex;
                    flex-direction: column;

                    @media (max-width: 600px) {
                        margin-top: 15px;
                        width: 100%;
                        flex-direction: row;
                        align-items: end;
                        justify-content: space-between;
                    }

                    h2 {
                        font-size: 23px;
                        font-weight: 600;
                        color: $primary-color;

                        span {
                            font-size: 15px;
                            font-weight: 400;
                        }

                        @media (max-width: 600px) {
                            font-size: 20px;
                            margin: 0;
                        }
                    }

                    .ant-btn {
                        background-color: $primary-color-light;
                        color: $primary-color;
                        font-weight: 600;
                        font-size: 15px;
                        padding: 20px 20px;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}

.tour__card_loading {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);

    .card_body {
        display: flex;
        gap: 20px;
        padding: 0;

        @media (max-width: 600px) {
            flex-direction: column;
        }

        .left {
            display: flex;
            justify-content: center;
            align-items: center;

            .ant-skeleton-image {
                width: 300px;
                height: 250px;
                border-radius: 0;

                @media (max-width: 1380px) {
                    width: 210px;
                }

                @media (max-width: 1280px) {
                    width: 220px;
                }

                @media (max-width: 1024px) {
                    width: 200px;
                }

                @media (max-width: 768px) {
                    width: 220px;
                }

                @media (max-width: 600px) {
                    width: 800px;
                    height: 160px;
                }
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            gap: 15px;
            justify-content: center;
            width: 90%;
            padding: 0 20px 0 0;

            .ant-skeleton-button {
                width: 60%;
                height: 40px;
            }

            @media (max-width: 600px) {
                padding: 0 20px;

                .ant-skeleton-button {
                    width: 100%;
                    height: 30px;
                }
            }
        }
    }
}

.review_cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .review_card {
        background-color: #fff;
        max-width: 400px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);

        @media (max-width: 768px) {
            max-width: 100%;
            width: 100%;
        }

        .review_header {
            display: flex;
            align-items: start;
            gap: 10px;

            .left {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid $primary-color-light;
                padding: 2px;

                img {
                    width: 100%;
                }
            }

            .right {
                h2 {
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0;
                    padding: 0;
                    color: $primary-color;
                }

                p {
                    font-size: 13px;
                    color: $gray-color;
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .rate {
            margin-top: 10px;
        }

        .review_content {
            margin-top: 10px;
            font-size: 14px;
            color: $gray-color;
        }

        .review_images {
            width: 100%;
            display: flex;
            gap: 10px;
            margin-top: 10px;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                width: 5px;
            }


            img {
                width: 150px;
                height: 100px;
                object-fit: cover;
                border-radius: 5px;
            }
        }
    }
}