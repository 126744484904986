@import '../../../../../theme/variables.scss';

.personal_details {
    .about {
        margin-top: 10px;
    }

    .personal_details__container {
        margin-top: 20px;

        .empty {
            border: 1px solid $very-light-gray-color;
            border-radius: 10px;
        }

        .ant-divider {
            margin: 5px 0;
        }

        .personal_details__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;

            @media (max-width: 600px) {
                flex-direction: column;
                align-items: start;
                gap: 10px;
            }

            .left {
                display: flex;
                align-items: center;

                @media (max-width: 600px) {
                    gap: 10px;
                    flex-direction: column;
                    align-items: start;
                }

                .label {
                    font-size: 16px;
                    color: $dark-color;
                    width: 140px;
                }

                .value {
                    font-size: 16px;
                    color: $dark-gray-color;
                    font-weight: 400;
                }

                .passport {
                    h5 {
                        color: $dark-gray-color;
                        font-size: 16px;
                        font-weight: 400;
                        display: flex;
                        gap: 10px;

                        @media (max-width: 600px) {
                            flex-direction: column;
                            align-items: start;
                        }

                        h6 {
                            font-size: 16px;
                            font-weight: 400;
                            width: 140px;

                            @media (max-width: 600px) {
                                width: wrap;
                            }
                        }
                    }
                }

                .address_text {
                    display: flex;
                    flex-wrap: wrap;
                }

                span {
                    font-size: 16px;
                    color: $medium-gray-color;
                    margin-right: 10px;
                    font-weight: 400;
                }
            }

            @media (max-width: 600px) {
                position: relative;

                .desktopEdit {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }


            .passport_left {
                align-items: start;
            }

            .ant-form {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                @media (max-width: 600px) {
                    flex-direction: column;
                    align-items: start;
                    gap: 0px;
                }

                .feilds {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    gap: 10px;

                    @media (max-width: 600px) {
                        flex-direction: column;
                        align-items: start;
                        gap: 0;
                    }
                }

                .passport {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0;
                    width: 400px;

                    @media (max-width: 600px) {
                        width: 100%;
                    }

                    .ant-input,
                    .ant-select {
                        width: 200px;

                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }

                    .passport_row {
                        display: flex;
                        gap: 10px;

                        @media (max-width: 600px) {
                            width: 100%;
                            flex-direction: column;
                            align-items: start;
                            gap: 0;
                        }

                        .passport_check {
                            display: flex;
                            align-items: start;
                            gap: 10px;
                        }
                    }
                }

                .address {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0;
                    width: 400px;

                    .ant-input {
                        width: 400px;
                    }

                    .address_top {
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }

                    @media (max-width: 600px) {
                        width: 100%;

                        .ant-input {
                            width: 100%;
                        }
                    }

                    .address_bottom {
                        display: flex;
                        gap: 10px;

                        .ant-input {
                            width: 195px;
                        }

                        @media (max-width: 600px) {
                            width: 100%;
                            flex-direction: column;
                            align-items: start;
                            gap: 0;

                            .ant-input {
                                width: 100%;
                            }
                        }
                    }
                }

                .actions {
                    display: flex;
                    gap: 10px;

                    @media (max-width: 600px) {
                        flex-direction: row-reverse;
                    }
                }

                @media (max-width: 600px) {
                    .ant-form-item {
                        width: 100%;
                    }

                    .ant-input,
                    .ant-select {
                        width: 100%;
                    }

                    .passport {
                        width: 100%;
                    }

                    .address {
                        width: 100%;
                    }
                }
            }
        }

        .profile_pic {
            justify-content: start;
            gap: 10px;
        }
    }
}