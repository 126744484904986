@import '../../../theme/variables.scss';

.tours_main {
    .hero {
        height: 340px;
        display: flex;
        align-items: center;

        .hero__content {
            text-align: start;
            margin-top: 100px;
        }

        @media (max-width: 768px) {
            height: 250px;

            .hero__content {
                margin-top: 50px;
            }
        }

        @media (max-width: 576px) {
            height: 200px;

            .hero__content {
                margin-top: 30px;
            }
        }
    }

    .destinations__content {
        background-color: $background-color;

        .breadcrumb__section {
            .filter__btn {
                display: none;
            }

            @media (max-width: 768px) {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .filter__btn {
                    display: block;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    color: $primary-color;
                    background-color: $primary-color-light;
                    border-radius: 5px;
                }
            }
        }
    }

    .main_items__list {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        gap: 40px;

        @media (max-width: 1024px) {
            gap: 20px;
        }

        .filter__section {
            width: 350px;
            background-color: $pure-white-color;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            height: 100%;

            @media (max-width: 768px) {
                display: none;
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;

                h2 {
                    font-size: 25px;
                    font-weight: 600;
                    color: $dark-color;
                    margin: 0;
                }

                .ant-btn {
                    background-color: $primary-color-light;
                    color: $primary-color;
                    font-weight: 600;
                    padding: 17px 20px;
                    border-radius: 20px;
                }

                @media (max-width: 1024px) {
                    h2 {
                        font-size: 20px;
                    }

                    .ant-btn {
                        padding: 10px 15px;
                        font-size: 14px;
                    }
                }
            }

            .ant-form-item {
                margin: 0;

                .days__slider {
                    display: flex;
                    gap: 15px;
                    justify-content: space-between;
                    align-items: center;

                    .slider {
                        width: 70%;

                        @media (max-width: 1404px) {
                            width: 60%;
                        }
                    }
                }
            }

            .ant-form-item-label {
                label {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    color: $dark-gray-color;
                }

                .form_label {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    margin: 0;
                    padding: 0;

                    p {
                        font-size: 20px;
                        color: $primary-color;
                        margin: 0;
                        font-weight: 500;
                    }

                    .ant-btn {
                        padding: 0;
                        margin: 0;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }

                @media (max-width: 1024px) {
                    label {
                        font-size: 16px;
                    }

                    .form_label {
                        p {
                            font-size: 16px;
                        }

                        .ant-btn {
                            font-size: 14px;
                        }
                    }
                }
            }

            .ant-radio-group {
                display: flex;
                flex-direction: column;

                .ant-radio-wrapper {
                    margin: 5px 0;
                    font-size: 16px;

                    .ant-radio-inner {
                        width: 20px;
                        height: 20px;
                        border-radius: 4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                @media (max-width: 1024px) {
                    .ant-radio-wrapper {
                        font-size: 14px;

                        .ant-radio-inner {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }

            .show_all_btn {
                font-size: 16px;
                padding: 0;
                margin: 0;
                font-weight: 500;
                margin-top: 10px;
            }
        }

        .items__list {
            width: 80%;

            @media (max-width: 1280px) {
                width: 72%;
            }

            @media (max-width: 768px) {
                width: 100%;
            }
        }

        .pagination {
            display: flex;
            justify-content: end;
            margin-top: 20px;
        }
    }

    .trip_planner {
        margin: 20px 0;

        .section_main {
            background-color: #fff;
            border-radius: 20px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }

        .custom_section_left {
            padding: 40px 0 40px 40px !important;

            @media (max-width: 768px) {
                padding: 20px 20px 0 20px !important;
            }
        }
    }
}

.filter__drawer {
    border-radius: 20px 20px 0 0;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h2 {
            font-size: 25px;
            font-weight: 600;
            color: $dark-color;
            margin: 0;
        }

        .btnn-close {
            font-size: 20px;
            color: $primary-color;
            font-weight: 600;
        }

        .btn-apply {
            background-color: $primary-color-light;
            color: $primary-color;
            font-weight: 600;
            padding: 17px 20px;
            border-radius: 20px;
        }

        @media (max-width: 1024px) {
            h2 {
                font-size: 20px;
            }

            .ant-btn {
                padding: 10px 15px;
                font-size: 14px;
            }
        }
    }

    .ant-form-item {
        margin: 0;
    }

    .ant-form-item-label {
        label {
            display: flex;
            align-items: center;
            font-size: 20px;
            color: $dark-gray-color;
        }

        .form_label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 0;
            padding: 0;

            p {
                font-size: 20px;
                color: $primary-color;
                margin: 0;
                font-weight: 500;
            }

            .ant-btn {
                padding: 0;
                margin: 0;
                font-size: 16px;
                font-weight: 600;
            }
        }

        @media (max-width: 1024px) {
            label {
                font-size: 16px;
            }

            .form_label {
                p {
                    font-size: 16px;
                }

                .ant-btn {
                    font-size: 14px;
                }
            }
        }
    }

    .ant-radio-group {
        display: flex;
        flex-direction: column;

        .ant-radio-wrapper {
            margin: 5px 0;
            font-size: 16px;

            .ant-radio-inner {
                width: 20px;
                height: 20px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        @media (max-width: 1024px) {
            .ant-radio-wrapper {
                font-size: 14px;

                .ant-radio-inner {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    .show_all_btn {
        font-size: 16px;
        padding: 0;
        margin: 0;
        font-weight: 500;
        margin-top: 10px;
    }
}