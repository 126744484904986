@import '../../../../theme/variables.scss';

.support_ticket {
    min-height: 80vh;

    .tickets_header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .actions {
            display: flex;
            align-items: center;
            gap: 10px;

            .add_btn {
                color: $primary-color;
                font-size: 15px;
                font-weight: 600;
                background-color: $primary-color-light;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px 20px;
                border: none;

                &:hover {
                    background-color: $primary-color !important;
                    color: $white-color !important;
                }
            }
        }
    }

    .support__container {
        .loading_container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40vh;
        }

        .no_tickets {
            border: 1px solid $medium-gray-color;
            margin-top: 20px;
            border-radius: 10px;
            padding: 40px 0;
        }

        .tickets_container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .ticket_card {
                background-color: #fff;
                display: flex;
                justify-content: space-between;
                padding: 10px 20px;
                border-radius: 10px;
                cursor: pointer;
                transition: all 0.3s ease;

                @media (max-width: 768px) {
                    padding: 10px;
                    flex-direction: column;

                    .ticket_card__header__actions {
                        margin-top: 10px;
                        display: flex;
                        gap: 10px;

                        .ant-tag {
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                &:hover {
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                }

                h2 {
                    font-size: 20px;
                    color: $primary-color;
                }

                .p_container {
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    p {
                        margin: 10px 0 0 0;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}