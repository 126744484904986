@import '../../../../theme/variables.scss';

.my_reviews {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .reviews {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .blog_card {
            padding: 15px 20px;
            border: none;
            border-radius: 10px;
            position: relative;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }

            .actions {
                position: absolute;
                top: 10px;
                right: 10px;
                display: flex;
                gap: 5px;

                .ant-btn {
                    font-size: 20px;
                    padding: 0 5px;
                }
            }

            .review_card__header {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 8px;

                h3 {
                    margin: 0;
                    font-size: 22px;
                    font-weight: 600;
                    color: $primary-color;
                }
            }

            .review_card__body {
                h5 {
                    color: $gray-color;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }
}