@import '../../../../../../theme/variables.scss';

.tour_booking_details_container {
    .p_container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .red {
            color: $red_orange_color;
        }

        .green {
            color: $success_color;
        }

        span {
            color: $gray-color;
        }
    }

    .travellers {
        h2 {
            margin: 0;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 600;
        }

        .p_container {
            margin-bottom: 10px;
            padding-bottom: 5px;
            border-bottom: 1px solid $very-light-gray-color;
        }
    }
}