@import '../../../theme/variables.scss';

.blogs_main {
    .hero {
        height: 340px;
        display: flex;
        align-items: center;

        .hero__content {
            text-align: start;
            margin-top: 150px;
        }

        @media (max-width: 768px) {
            height: 250px;

            .hero__content {
                margin-top: 50px;
            }
        }

        @media (max-width: 576px) {
            height: 200px;

            .hero__content {
                margin-top: 30px;
            }
        }

        .hero__background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -2;

            .lazy-load-image-background {
                width: 100%;
            }

            img {
                width: 100%;
                object-fit: cover;
                transform: translateY(-10%) !important;

                @media (max-width: 768px) {
                    transform: translateY(0) !important;
                }

                @media (max-width: 576px) {
                    transform: translateY(0);
                    height: 500px;
                    object-fit: cover;
                }
            }
        }
    }

    .destinations__content {
        background-color: $background-color;

        .breadcrumb__section {
            .filter__btn {
                display: none;
            }

            @media (max-width: 576px) {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .filter__btn {
                    display: block;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    color: $primary-color;
                    background-color: $primary-color-light;
                    border-radius: 5px;
                }
            }
        }
    }

    .blogs_main {
        min-height: 50vh;
    }

    .package__card {
        min-height: 460px;

        .author {
            display: flex;
            align-items: center;
            gap: 10px;

            .author_image {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid $primary-color-light;
                padding: 2px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }

            .author_name {
                h3 {
                    margin: 0;
                    font-size: 18px;
                    color: $dark-color;
                }

                p {
                    margin: 0;
                    font-size: 12px;
                    color: $gray-color;
                }
            }
        }
    }

    .main_items__list {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        gap: 40px;

        @media (max-width: 1024px) {
            gap: 20px;
        }
    }
}