@import '../../../theme/variables.scss';

.custom_tours_main {
    .hero {
        height: 350px;
        display: flex;
        align-items: center;

        .hero__background_custom_tours {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -2;

            .lazy-load-image-background {
                width: 100%;
            }

            img {
                width: 100%;
                object-fit: cover;
                transform: translateY(-6%);

                @media (max-width: 768px) {
                    transform: translateY(0);
                }

                @media (max-width: 576px) {
                    transform: translateY(0);
                    height: 500px;
                    object-fit: cover;
                }
            }
        }

        .hero__content {
            text-align: start;
            margin-top: 200px;

            @media (max-width: 576px) {
                margin-top: 50px !important;
            }

            .section_subtitle {
                font-size: 25px;
                color: $primary-color;
                font-weight: 600;
                margin-bottom: 20px;

                @media (max-width: 600px) {
                    font-size: 20px;
                }
            }

            @media (max-width: 576px) {
                .section_title {
                    font-size: 30px;
                }
            }

            .hero_btns {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                margin-top: 30px;
                z-index: 1;
                position: relative;

                @media (max-width: 576px) {
                    gap: 10px;
                    margin-top: 20px;
                }

                .ant-btn {
                    padding: 25px;
                    font-size: 15px;
                    font-weight: 600;

                    @media (max-width: 576px) {
                        padding: 20px;
                        font-size: 12px;
                    }
                }

                .trip_plan_btn {
                    color: #fff;
                    background-color: $primary-color;

                    &:hover {
                        color: $primary-color;
                        background-color: $primary-color-light;
                    }
                }

                .event_plan_btn {
                    color: $primary-color;
                    background-color: #EFFCFD;

                    &:hover {
                        color: #fff;
                        background-color: $primary-color-light;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            height: 250px;

            .hero__content {
                margin-top: 50px;
            }
        }

        @media (max-width: 576px) {
            height: 200px;

            .hero__content {
                margin-top: 30px;
            }
        }
    }

    .custom_tours_home {
        section {
            margin: 0 0 30px 0;
            background-color: #fff;
            padding: 20px 30px;
            border-radius: 10px;

            .paragraph {
                margin-top: 15px;
            }
        }

        .explain_path_section {
            .plan_steps {
                display: flex;
                justify-content: space-between;
                margin-top: 40px;

                @media (max-width: 576px) {
                    flex-direction: column;
                    gap: 20px;
                    margin-top: 20px;
                }

                .plan_step {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    flex: 1;

                    .image_container {
                        width: 100px;
                        padding: 10px;
                        background: linear-gradient(90deg, #1298ff1a 0%, #c5eff38b 100%);
                        margin-bottom: 15px;
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                        border-bottom-right-radius: 20px;

                        img {
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                    h2 {
                        font-size: 16px;
                        font-weight: 600;
                        color: $light-2-color;
                    }

                    p {
                        margin-top: 8px;
                        font-size: 14px;
                        color: $gray-color;
                        font-weight: 400;
                    }
                }

                .step_arrow {
                    stroke-width: 1px;
                    stroke: #AFAFAF;
                    width: 250px;

                    @media (max-width: 1440px) {
                        width: 200px;
                    }

                    @media (max-width: 1280px) {
                        width: 150px;
                    }

                    @media (max-width: 576px) {
                        transform: rotate(90deg);
                        width: 80px;
                        stroke-width: 4px;
                    }

                    svg {
                        width: 100%;

                        @media (max-width: 576px) {
                            width: 100%;
                        }
                    }
                }

                .arrow_1 {
                    margin-top: 40px;

                    @media (max-width: 576px) {
                        margin: 10px auto 20px auto;
                    }
                }

                .arrow_2 {
                    margin-top: 100px;

                    @media (max-width: 576px) {
                        margin: 20px auto 20px auto;
                    }
                }
            }
        }

        .tipts_section {
            .tipts_container {
                margin-top: 30px;

                .ant-row {
                    column-gap: 20px;
                    row-gap: 20px;

                    @media (max-width: 576px) {
                        column-gap: 0;
                        row-gap: 20px;
                    }

                    .ant-col {
                        border-radius: 20px;
                        padding: 16px 25px;

                        h3 {
                            font-size: 22px;
                            font-weight: 600;
                            color: $primary-color;
                        }

                        p {
                            margin: 5px 0 0 0;
                            font-size: 14px;
                            color: $dark-color;
                        }
                    }

                    .tip_1 {
                        background: linear-gradient(90deg, rgba(103, 220, 211, 0.13) 0%, rgba(0, 116, 205, 0.10) 100%);
                    }

                    .tip_2 {
                        background: linear-gradient(90deg, rgba(0, 215, 254, 0.11) 0%, rgba(103, 220, 211, 0.11) 100%);
                    }

                    .tip_3 {
                        background: linear-gradient(90deg, rgba(215, 255, 250, 0.33) 0%, rgba(222, 212, 244, 0.23) 100%);
                    }

                    .tip_4 {
                        background: linear-gradient(90deg, rgba(117, 255, 255, 0.16) 0%, rgba(151, 180, 255, 0.17) 100%);
                    }

                    .tip_5 {
                        background: linear-gradient(90deg, rgba(103, 220, 211, 0.13) 0%, rgba(0, 116, 205, 0.10) 100%);
                    }

                    .tip_6 {
                        background: linear-gradient(90deg, rgba(0, 215, 254, 0.11) 0%, rgba(103, 220, 211, 0.11) 100%);
                    }
                }
            }

            .action_buttons {
                margin-top: 30px;
                display: flex;
                align-items: center;
                gap: 20px;
            }
        }

        .custom_events_section {
            .custom_events_container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                margin-top: 30px;

                @media (max-width: 900px) {
                    flex-direction: column;
                }

                .left {
                    width: 52%;

                    @media (max-width: 1280px) {
                        width: 50%;
                    }

                    @media (max-width: 900px) {
                        width: 100%;
                        display: none;
                    }

                    .lazy-load-image-background {
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                    }
                }

                .right {
                    width: 45%;

                    @media (max-width: 1280px) {
                        width: 48%;
                    }

                    @media (max-width: 900px) {
                        width: 100%;
                    }

                    h1 {
                        font-size: 24px;
                        font-weight: 600;
                        color: $primary-color;
                        margin-bottom: 10px;

                        @media (max-width: 1366px) {
                            font-size: 20px;
                        }
                    }

                    ul {
                        margin-top: 20px;

                        li {
                            font-size: 16px;
                            color: $primary-color;
                            margin-bottom: 10px;
                        }

                        @media (max-width: 1366px) {
                            margin-top: 10px;
                        }

                        @media (max-width: 1280px) {
                            margin-top: 10px;
                            margin-bottom: 0;

                            li {
                                font-size: 14px;
                                margin-bottom: 5px;
                            }
                        }
                    }

                    p {
                        font-size: 16px;
                        color: $primary-color;
                        margin-bottom: 10px;

                        @media (max-width: 1280px) {
                            font-size: 14px;
                        }
                    }

                    .include_items {
                        height: 130px;

                        @media (max-width: 1366px) {
                            height: 120px;
                        }

                        @media (max-width: 1280px) {
                            height: 80px;
                        }

                        @media (max-width: 900px) {
                            height: 100%;
                        }
                    }

                    .includes {
                        position: absolute;
                        right: 0;

                        @media (max-width: 900px) {
                            position: relative;
                            margin-top: 15px;
                        }

                        .column {
                            display: flex;
                            gap: 10px;
                            align-items: end;
                            justify-content: flex-end;

                            @media (max-width: 900px) {
                                justify-content: flex-start;
                            }

                            h3 {
                                font-size: 14px;
                                color: $primary-color;
                                font-weight: 500;
                                background: linear-gradient(90deg, #EBFAF9 0%, #E6F1FA 100%);
                                padding: 20px 25px;
                                border-radius: 10px;
                                box-shadow: 0px 0px2px rgba(0, 0, 0, 0.1);
                                transition: all 0.3s;

                                &:hover {
                                    transform: scale(1.05);
                                }

                                @media (max-width: 1366px) {
                                    padding: 15px;
                                }

                                @media (max-width: 1280px) {
                                    padding: 10px;
                                    font-size: 13px;
                                }
                            }
                        }
                    }

                    .action_buttons {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        margin-top: 10px;
                    }
                }
            }
        }

        .ad_card {
            background: linear-gradient(270deg, rgba(103, 216, 220, 0.55) 0%, rgba(212, 241, 244, 0.22) 51.59%);
            box-shadow: 0px 0px 20.1px -4px rgba(0, 0, 0, 0.095);
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 30px;

            @media (max-width: 576px) {
                flex-direction: column;
                gap: 20px;
            }

            .left {
                width: 50%;
                padding: 0 0 0 30px;

                h1 {
                    font-size: 64px;
                    font-weight: 700;
                    color: $primary-color;
                }

                p {
                    font-size: 22px;
                    color: $dark-color;
                    line-height: 1.6;
                    font-weight: 500;
                    width: 80%;
                    margin: 15px 0;
                }

                .action_buttons {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                }

                @media (max-width: 1366px) {
                    h1 {
                        font-size: 50px;
                    }

                    p {
                        font-size: 18px;
                    }
                }

                @media (max-width: 1280px) {
                    h1 {
                        font-size: 40px;
                    }

                    p {
                        font-size: 16px;
                    }
                }

                @media (max-width: 900px) {
                    h1 {
                        font-size: 30px;
                    }

                    p {
                        font-size: 14px;
                        width: 100%;
                    }
                }

                @media (max-width: 576px) {
                    width: 100%;
                    padding: 30px 30px 0 30px;
                }
            }

            .right {
                width: 50%;

                @media (max-width: 576px) {
                    width: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}