@import '../../../../theme/variables.scss';

.accommodationPackage {

    @media (max-width: 1100px) {
        margin-top: 60px;
    }

    @media (max-width: 768px) {
        margin-top: 0px;
    }

    .hero__content_header {
        display: flex;
        gap: 50px;
        align-items: center;
        justify-content: space-between;

        .actions {
            display: flex;
            gap: 18px;

            .button {
                padding: 0;
                font-size: 30px;
            }

            @media (max-width: 768px) {
                align-items: center;

                .button {
                    font-size: 24px;
                }
            }

            .dark {
                color: $primary-color;

                &:hover {
                    color: $light-2-color;
                }

                &.active {
                    color: $light-1-color;
                }
            }
        }
    }

    .image_gallery {
        display: flex;
        gap: 20px;
        margin-top: 20px;
        height: 500px;
        position: relative;

        @media (max-width: 1366px) {
            height: 400px;
        }

        @media (max-width: 1024px) {
            height: 300px;
        }

        .total {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: rgba(0, 0, 0, 0.6);
            color: $white-color;
            padding: 5px 10px;
            border-radius: 5px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 10px;
            cursor: pointer;
        }

        .left {
            width: 70%;
        }

        .right {
            width: 30%;

            .images_container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                height: 100%;

                span {
                    height: 50%;

                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .more_images {
                display: none;
            }
        }

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 5px;
            margin-top: 10px;
            height: auto;

            .total {
                bottom: 5px;
                right: 5px;
                font-size: 12px;
            }

            img {
                border-radius: 5px;
            }

            .left {
                width: 100%;
            }

            .right {
                width: 100%;

                .images_container {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;

                    span {
                        height: 70px;
                        width: 100%;

                        img {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .tour_package_content_body {
        display: flex;
        justify-content: start !important;
        margin-top: 20px;
        gap: 20px;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 10px;
        }

        .content__container {
            order: 1;
            max-width: 69%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media (max-width: 1280px) {
                max-width: 70%;
            }

            @media (max-width: 768px) {
                order: 2;
                max-width: 100%;
            }

            section {
                border-radius: 10px;
                padding: 20px;
                background-color: #fff;
            }

            .sec__1 {
                display: flex;
                gap: 24px;
                flex-direction: column;

                .tags {
                    display: flex;
                    gap: 5px;
                    flex-wrap: wrap;

                    .ant-tag {
                        padding: 5px 10px;
                        background-color: transparent;
                        color: $light-2-color;
                        border-radius: 5px;
                        border: 1px solid $light-2-color;
                        font-size: 14px;
                    }

                    @media (max-width: 576px) {
                        gap: 3px;

                        .ant-tag {
                            font-size: 12px;
                            padding: 4px 10px;
                        }
                    }
                }

                .short_details {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .row_cus {
                        display: flex;
                        gap: 30px;

                        .col_cus {
                            display: flex;
                            gap: 10px;
                            align-items: center;

                            .title {
                                font-size: 16px;
                                color: $gray-color;
                            }

                            .value {
                                font-size: 16px;
                                color: $primary-color;
                            }
                        }

                        .prem {
                            background-color: #d1b800;
                            padding: 10px 20px;
                            border-radius: 5px;
                            font-weight: 600;
                            width: fit-content;
                        }

                        @media (max-width: 576px) {
                            flex-direction: column;
                            gap: 10px;

                            .col_cus {
                                .title {
                                    font-size: 14px;
                                }

                                .value {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                .about {
                    .quill {
                        margin-top: 10px;
                    }
                }

                .highlights {
                    .list {
                        all: unset;
                        margin-top: 15px;
                        padding: 0;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 11px;

                        p {
                            margin: 0;
                            padding: 0;
                            font-weight: 500;
                            border: 1px solid $primary-color;
                            color: $primary-color;
                            background-color: transparent;
                            padding: 5px 10px;
                            border-radius: 5px;
                        }

                        .anticon {
                            color: $primary-color;
                            font-size: 14px;
                        }

                        @media (max-width: 576px) {
                            gap: 10px;

                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .meals {
                    .list {
                        all: unset;
                        margin-top: 15px;
                        padding: 0;
                        display: flex;
                        gap: 10px;

                        p {
                            margin: 0;
                            padding: 0;
                            font-weight: 500;
                            color: #fff;
                            background-color: $primary-color;
                            padding: 5px 10px;
                            border: 1px solid $primary-color;
                            border-radius: 5px;
                        }

                        .anticon {
                            color: $primary-color;
                            font-size: 14px;
                        }

                        @media (max-width: 576px) {
                            gap: 10px;

                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .sec__2 {
                .slider {
                    margin: 15px 0 20px 0;

                    .react-multiple-carousel__arrow {
                        z-index: 10 !important;
                    }

                    .custom-dot-list-style {
                        display: none;
                    }

                    .destination_card {
                        margin: 0 5px;
                        border: none;
                    }

                    .card_image {
                        height: 170px;
                        border-radius: 10px;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            border-radius: 10px;
                        }
                    }

                    .card_body {
                        padding: 0;
                    }

                    .card_title {
                        margin: 0;
                        margin-top: 10px;
                        font-size: 18px;
                        font-weight: 600;
                        color: $dark-color;
                        padding: 0;
                    }
                }

                .more_about_destination__content {
                    margin: 20px 0;

                    .button {
                        background-color: #063d6715;
                        color: $primary-color;
                        margin-top: 10px;
                    }
                }

                .route {
                    margin-top: 15px;
                    width: 100%;
                    height: 400px;
                    background-color: rgba(226, 226, 226, 0.662);
                    border-radius: 10px;

                    iframe {
                        width: 100%;
                        height: 100%;
                        border: none;
                        border-radius: 10px;
                    }
                }

                .accommodationFacilities {
                    .facilities {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;
                        margin-top: 15px;

                        .facility {
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            padding: 10px 10px;
                            background-color: $primary-color;
                            color: $white-color;
                            border-radius: 5px;

                            .anticon {
                                color: $white-color;
                            }
                        }

                        @media (max-width: 576px) {
                            gap: 5px;

                            .facility {
                                padding: 5px 10px;

                                .icon {
                                    font-size: 14px;
                                }

                                .title {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }

            .sec__3 {
                .meals {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    margin-top: 10px;

                    .meal {
                        display: flex;
                        gap: 20px;
                        align-items: center;
                        justify-content: space-between;
                        background-color: $background-color;
                        padding: 10px 15px;
                        border-radius: 10px;

                        .meal_info {
                            width: 100%;

                            .title {
                                font-size: 18px;
                                font-weight: 600;
                                color: $primary-color;
                            }

                            .icon {
                                font-size: 14px;
                                color: $primary-color;
                                background-color: $light-3-color;
                                padding: 5px 10px;
                                border-radius: 5px;
                                width: fit-content;
                                margin: 10px 0;
                                font-weight: 500;
                            }

                            .descriptoin {
                                font-size: 16px;
                                color: $dark-color;
                            }
                        }

                        .price {
                            height: 100%;
                            font-size: 18px;
                            font-weight: 600;
                            color: $primary-color;
                            display: flex;
                            gap: 5px;
                            align-items: center;
                            background-color: $primary-color-light;
                            padding: 10px 15px;
                            border-radius: 10px;

                            h2 {
                                margin: 0;
                            }

                            p {
                                margin: 0;
                            }
                        }

                        @media (max-width: 576px) {
                            flex-direction: column;
                            gap: 10px;
                            align-items: start;

                            .meal_info {
                                .title {
                                    font-size: 16px;
                                }

                                .icon {
                                    font-size: 12px;
                                }

                                .descriptoin {
                                    font-size: 14px;
                                }
                            }

                            .price {
                                width: 100%;
                                font-size: 16px;
                                flex-direction: row;
                                width: max-content;

                                h2 {
                                    font-size: 16px;
                                }

                                p {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            .sec__4 {
                .house-rules {
                    padding-top: 20px;

                    .ant-collapse-header-text {
                        font-size: 16px;
                        font-weight: 600;
                        color: $primary-color;
                    }

                    .check-times {
                        display: flex;
                        gap: 20px;
                        margin-bottom: 30px;

                        .check-time {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 10px;
                            background-color: $primary-color;
                            padding: 10px 20px;
                            border-radius: 10px;

                            &:nth-child(2) {
                                background-color: $light-1-color;
                            }

                            svg {
                                font-size: 34px;
                                color: #d6d6d6;
                            }

                            h4 {
                                margin: 0;
                                font-size: 16px;
                                color: #d4d4d4;
                            }

                            p {
                                margin: 5px 0 0;
                                font-size: 14px;
                                color: #ffffff;
                            }
                        }
                    }

                    .policies {
                        .ant-collapse {
                            border: none;
                            background: transparent;

                            .ant-collapse-item {
                                margin-bottom: 10px;
                                border: 1px solid #eee;
                                border-radius: 8px;
                            }
                        }
                    }
                }

                h3 {
                    margin-top: 15px;
                    font-size: 20px;
                    font-weight: 700;
                    color: $primary-color;
                }

                .list {
                    all: unset;
                    margin-top: 15px;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    justify-content: start;
                    align-items: start;

                    span {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        p {
                            margin: 0;
                            padding: 0;
                        }

                        .anticon {
                            color: $primary-color;
                            font-size: 14px;
                        }
                    }

                    .include {
                        .ql-container {
                            margin: 8px 0 0 30px;
                        }
                    }

                    @media (max-width: 576px) {
                        gap: 10px;

                        p {
                            font-size: 14px;
                        }
                    }
                }
            }

            .sec__5 {
                .other-packages {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding: 20px 0;

                    .package-card {
                        display: flex;
                        border-radius: 12px;
                        overflow: hidden;
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                        cursor: pointer;
                        transition: all 0.3s ease;
                        background: white;

                        &:hover {
                            transform: translateY(-5px);
                            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
                        }

                        .package-image {
                            position: relative;
                            width: 300px;
                            min-width: 300px;
                            height: 200px;

                            img {
                                height: 300px;
                                width: 100%;
                                object-fit: cover;
                            }

                            .package-type {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                background: $primary-color;
                                color: white;
                                padding: 5px 10px;
                                border-radius: 5px;
                                font-size: 12px;
                                font-weight: 500;
                            }
                        }

                        .package-content {
                            flex: 1;
                            padding: 20px;

                            h3 {
                                margin: 0 0 15px;
                                font-size: 20px;
                            }

                            .package-details {
                                margin-bottom: 15px;

                                .price {
                                    font-size: 24px;
                                    font-weight: bold;
                                    color: $primary-color;
                                    margin: 0;

                                    span {
                                        font-size: 14px;
                                        color: #666;
                                    }
                                }

                                .details {
                                    display: flex;
                                    gap: 15px;
                                    margin-top: 10px;

                                    p {
                                        display: flex;
                                        align-items: center;
                                        gap: 5px;
                                        margin: 0;
                                        color: #666;
                                        font-size: 14px;
                                    }
                                }
                            }

                            .facilities {
                                display: flex;
                                gap: 8px;
                                flex-wrap: wrap;

                                span {
                                    background: #f5f5f5;
                                    padding: 4px 8px;
                                    border-radius: 4px;
                                    font-size: 12px;
                                    color: #666;
                                }
                            }
                        }

                        @media screen and (max-width: 768px) {
                            flex-direction: column;

                            .package-image {
                                width: 100%;
                                min-width: 100%;
                                height: 200px;

                                img {
                                    height: 100%;
                                }
                            }

                            .package-content {
                                padding: 15px;
                            }
                        }
                    }
                }
            }
        }

        .buy_now {
            order: 2;
            width: 30%;
            height: 100%;
            border-radius: 10px;
            padding: 20px;
            background-color: #fff;
            display: flex;
            flex-direction: column;

            @media (max-width: 768px) {
                width: 100%;
                order: 1;
            }

            .price {
                font-size: 26px;
                color: $primary-color;
                font-weight: 600;
                margin: 0 0 15px 0;

                span {
                    font-size: 16px;
                }
            }

            p {
                margin: 10px 0 15px 0;
            }

            .button_p {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 25px 20px;
                margin-bottom: 10px;
                font-weight: 600;

                svg {
                    font-size: 20px;
                }
            }

            .dark {
                color: $primary-color;
                background-color: $primary-color-light;

                &:hover {
                    color: $white-color;
                    background-color: $primary-color;
                }
            }

            .inquire {
                color: $light-2-color;
                border: 1px solid $light-2-color;

                &:hover {
                    color: $primary-color;
                    border: 1px solid $primary-color;
                }
            }

            .more {
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 1500px) {
                    flex-direction: column;
                    gap: 20px;
                }

                @media (max-width: 768px) {
                    flex-direction: row;
                    gap: 20px;
                }

                @media (max-width: 576px) {
                    flex-direction: column;
                    gap: 20px;
                }

                .button {
                    font-size: 16px;

                    svg {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .blogs {
        margin-top: 30px;

        .package__card {
            min-height: 460px;
            width: 100%;

            .author {
                display: flex;
                align-items: center;
                gap: 10px;

                .author_image {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid $primary-color-light;
                    padding: 2px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                .author_name {
                    h3 {
                        margin: 0;
                        font-size: 18px;
                        color: $dark-color;
                    }

                    p {
                        margin: 0;
                        font-size: 12px;
                        color: $gray-color;
                    }
                }
            }
        }
    }
}