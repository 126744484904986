@import '../../../../theme/variables.scss';

.support {
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .support__cards {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        gap: 20px;
        justify-content: center;
        align-items: center;

        .support_card {
            max-width: 500px;
            width: 100%;
            border-radius: 15px;
            background-color: #fff;
            padding: 20px;
            transition: all 0.3s ease;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
            }

            .icon {
                svg {
                    font-size: 40px;
                    color: $primary-color;
                }
            }

            h2 {
                font-size: 34px;
                font-weight: 500;
                color: $primary-color;
                margin: 5px 0;
            }

            p {
                font-size: 16px;
                color: $dark-color;
                line-height: 1.6;

                a {
                    color: $light-2-color;
                    text-decoration: none;
                }
            }
        }
    }
}