.section__all__tour__content {
    margin-top: 36px;

    @media (max-width: 768px) {
        margin-top: 16px;
    }

    .nutton_conteiner {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}