@import '../../../../../theme/variables.scss';

.custom_tour_start_planing {

    .custom_tour_start_planing__form {

        .form_item_label {
            width: 130px;
            text-align: start;
        }

        .fav_selector {
            margin: 0;

            .icon-wrapper {
                position: relative;
                padding: 0 30px;
            }

            .icon-wrapper .anticon {
                position: absolute;
                top: -2px;
                width: 18px;
                height: 18px;
                font-size: 20px;
                line-height: 1;
            }

            .icon-wrapper .anticon:first-child {
                inset-inline-start: 0;
            }

            .icon-wrapper .anticon:last-child {
                inset-inline-end: 0;
            }
        }
    }

    .ant-slider-track {
        background-color: $primary-color-light;
    }

    .date_selector {
        .heder_2 {
            margin-bottom: 15px;
        }
    }

    .qty_selector {
        .wrapper {
            margin-top: 20px;
        }

        .quantityItem {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                display: flex;
                align-items: center;
                gap: 10px;

                h3 {
                    font-size: 20px;
                    font-weight: 600;
                    color: $primary-color;
                }

                h4 {
                    font-size: 18px;
                    font-weight: 400;
                    color: $gray-color;
                }
            }
        }
    }

    .room_selector {
        .radio_group_vehicle {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 10px 0 0 0;

            .room_type {
                background-color: $background-color;
                padding: 10px;
                border-radius: 10px;

                .flex {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;

                    @media (max-width: 600px) {
                        flex-direction: column;
                        gap: 10px;
                    }

                    .image {
                        width: 200px;

                        .lazy-load-image-background {
                            width: 200px;

                            img {
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }

                h5 {
                    font-size: 18px;
                    font-weight: 600;
                    color: $primary-color;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: $gray-color;
                }
            }
        }
    }
}