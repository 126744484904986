@import '../../../theme//variables.scss';

.header__desktop {
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 1;

    &.scroll {
        background: #fff;
        height: 80px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        z-index: 100;
    }

    .header__desktop__inner {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $navbar_padding;

        .left {
            display: flex;
            align-items: center;
            gap: 50px;

            .header__desktop__logo {
                width: 180px;

                img {
                    width: 100%;
                }
            }

            .header__desktop__menu {
                display: flex;
                align-items: center;
                gap: 25px;

                .nav__item {
                    font-size: 16px;
                    font-weight: 500;
                    color: $primary-color;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    &:hover {
                        color: $light_2_color;
                    }
                }

                .active {
                    position: relative;
                    transition: all 0.3s ease;

                    &:after {
                        position: absolute;
                        content: '';
                        display: block;
                        width: 100%;
                        height: 2px;
                        background: $primary-color;
                        transform: translateY(4px);
                    }
                }
            }
        }

        .header__desktop__actions {
            display: flex;
            gap: 20px;
            align-items: center;

            .ant-divider {
                height: 15px;
                margin: 0;
                padding: 0;
                background: $light-gray-color;
            }

            .currency_selector {
                cursor: pointer;
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: $primary-color;
            }

            .country_selector {
                margin-bottom: 4px;
                cursor: pointer;

                img {
                    width: 40px;
                    border-radius: 3px;
                }
            }

            .profile-button {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: block;
                    transition: all 0.3s ease;
                    border: 1px solid $light-2-color;
                    padding: 2px;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

                    @media (max-width: 1480px) {
                        width: 35px;
                        height: 35px;
                    }
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }

            .divider {
                width: 1px;
                height: 26px;
                background: $light-gray-color;
            }

            .search {
                .anticon {
                    font-size: 20px;
                    color: $primary-color;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .header__desktop__inner {
            padding: 0 40px;

            .left {
                gap: 20px;

                .header__desktop__logo {
                    width: 150px;
                }

                .header__desktop__menu {
                    gap: 20px;

                    .nav__item {
                        font-size: 15px;
                    }
                }
            }

            .header__desktop__actions {
                gap: 15px;

                .country_currency__selector {
                    gap: 5px;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .header__desktop__inner {
            padding: 0 20px;

            .left {
                gap: 20px;

                .header__desktop__logo {
                    width: 120px;
                }

                .header__desktop__menu {
                    gap: 15px;

                    .nav__item {
                        font-size: 14px;
                    }
                }
            }

            .header__desktop__actions {
                gap: 10px;


                .country_selector {
                    font-size: 14px;
                }

                .search {
                    .anticon {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .header__desktop__inner {
            flex-direction: column;
            align-items: flex-start;
            padding: 0 15px;

            .left {
                gap: 10px;

                .header__desktop__logo {
                    width: 100px;
                }

                .header__desktop__menu {
                    gap: 10px;

                    .nav__item {
                        font-size: 13px;
                    }
                }
            }

            .header__desktop__actions {
                width: 100%;
                justify-content: space-between;

                .country_currency__selector {

                    .country_selector,
                    .currency_selector {
                        font-size: 13px;
                    }
                }

                .search {
                    .anticon {
                        font-size: 16px;
                    }
                }

            }
        }
    }
}



@media (max-width: 830pxpx) {
    .header__desktop {
        display: none;
    }
}


.country_select_menu_item {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $primary-color;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background: $light-4-color;
    }
}


.header__mobile {
    width: 100%;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;

    .header__mobile__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .header__mobile__logo {
        img {
            height: 40px;
        }
    }

    .anticon {
        font-size: 20px;
        color: #333;
        cursor: pointer;
    }
}

.menu_items_mobile {
    .country_currency {
        display: flex;
        align-items: center;
        gap: 20px;

        .currency_selector {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .nav__item {
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: 500;
        color: #333;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;

        .anticon {
            font-size: 20px;
            color: #333;
            margin-right: 10px;
        }
    }

    .active {
        color: $primary-color;
        font-weight: 700;
        text-shadow: 0 0px 30px rgba(0, 255, 221, 0.77);
    }

    .country_selector {
        img {
            width: 50px;
            border-radius: 3px;
        }
    }

    .ant-collapse-item {
        .ant-collapse-header {
            border-radius: 0;
            padding: 0;
        }

        .ant-collapse-content-box {
            padding: 0;
            padding-block: 0 !important;
        }

        .destination__types {
            display: flex;
            flex-direction: column;
            gap: 25px;
            margin: 15px 0 0 32px;

            .destination__item {
                font-size: 18px;
                font-weight: 500;
                color: #333;
                cursor: pointer;
                transition: all 0.3s ease;
                display: flex;
                align-items: center;
                color: $primary-color;

                svg {
                    font-size: 20px;
                    margin-right: 10px;
                }

                &:hover {
                    color: $light_2_color;
                }
            }
        }
    }

    .profile-button {
        display: flex;
        align-items: center;
        justify-content: start;
        cursor: pointer;
        margin-top: 20px;

        .profile-name {
            margin: 0 0 0 10px;

            p {
                margin: 0;
                font-size: 18px;
                font-weight: 600;
                color: $primary-color;
                margin-bottom: 2px;
            }

            span {
                font-size: 14px;
                font-weight: 500;
                color: $light-2-color;
            }
        }

        img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: block;
            transition: all 0.3s ease;
            border: 1px solid $light-2-color;
            padding: 2px;

            @media (max-width: 1480px) {
                width: 65px;
                height: 65px;
            }
        }

        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }

    .custom_primary_btn__mobile {
        color: $primary-color;
        font-size: 15px;
        font-weight: 600;
        background-color: $primary-color-light;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 25px;
        width: 100%;
        border: none;
        margin: 20px auto;

        &:hover {
            background-color: $primary-color !important;
            color: $white-color !important;
        }
    }
}

.mobile_nav_drawer_root {
    .ant-drawer-content-wrapper {
        box-shadow: none;
    }
}



// Destinations Popup
.destination_popover {

    .ant-popover-arrow {
        &::before {
            background: #ffffffa3;
            backdrop-filter: blur(5px);
        }
    }

    .ant-popover-inner {
        backdrop-filter: blur(5px);
        background: #ffffffa3;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    .destination__types {
        display: flex;
        gap: 25px;
        flex-direction: column;
        padding: 10px 20px;

        .destination__item {
            font-size: 20px;
            display: flex;
            align-items: center;
            gap: 20px;
            font-weight: 500;
            color: $primary-color;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                transform: scale(1.1);
            }

            svg {
                font-size: 20px;
                color: $primary-color;
            }

            @media (max-width: 1280px) {
                font-size: 18px;
            }

            @media (max-width: 1024px) {
                font-size: 16px;
            }
        }
    }
}



.profile-dropdown {
    .my-profile {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 4px 6px;
        padding-top: 6px;
        font-size: 15px;

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: block;
            transition: all 0.3s ease;
            border: 1px solid $light-2-color;
            padding: 2px;
        }
    }

    a {
        text-decoration: none;
    }

    .menu-item {
        padding: 10px 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 200px;

        .anticon {
            font-size: 22px;
        }
    }

    .logout {
        cursor: pointer;
    }
}

.mobile_nav_dropdown_lang {
    position: absolute;
    height: 300px;
    overflow: scroll;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .country_select_menu_item {
        img {
            width: 30px;
            border-radius: 3px;
        }

        span {
            font-size: 20px;
        }
    }
}

.tour_menu_popover {
    .tour_menu {
        display: flex;
        align-items: center;

        .item {
            width: 240px;
            padding: 10px 20px;
            cursor: pointer;
            transition: all 0.3s ease;

            .icon {
                width: 70px;
                margin: auto;

                img {
                    height: 100%;
                    width: 100%;
                    background-color: transparent;
                }
            }

            p {
                font-size: 20px;
                font-weight: 500;
                color: $primary-color;
                margin: 0 0 10px 0;
            }

            span {
                font-size: 15px;
                font-weight: 400;
            }

            &:hover {
                background: $light-4-color;
            }
        }
    }
}