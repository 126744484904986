@import "../../../theme/variables.scss";

.about_srilanka {
    .top_padding_container {
        .hero {
            display: flex;
            justify-content: space-between;
            gap: 40px;
            align-items: center;

            .left {
                flex: 1;

                h2 {
                    margin-top: 5px;
                }

                .paragraph {
                    margin-top: 20px;
                }
            }

            .right {
                flex: 1;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 20px;
                }
            }

            @media (max-width: 1280px) {
                .left {
                    flex: 2;
                }

                .right {
                    flex: 1;
                }
            }

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 20px;

                .left {
                    h2 {
                        font-size: 30px;
                    }

                    .paragraph {
                        font-size: 16px;
                    }
                }

                .right {
                    img {
                        border-radius: 10px;
                    }
                }
            }
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        gap: 40px;

        .left {
            flex: 1;

            h2 {
                margin-top: 5px;
            }

            .paragraph {
                margin-top: 20px;
            }

            ul {
                li {
                    margin-bottom: 10px;
                    font-size: 18px;
                    color: $primary-color;

                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                }
            }
        }

        .right {
            flex: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 20px;
            }
        }

        @media (max-width: 1280px) {
            .left {
                flex: 3;
            }

            .right {
                flex: 2;
            }
        }

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 20px;

            .left {
                h2 {
                    font-size: 30px;
                }

                .paragraph {
                    font-size: 16px;
                }

                ul {
                    li {
                        font-size: 16px;
                    }
                }
            }

            .right {
                margin-top: 10px;

                img {
                    border-radius: 10px;
                }
            }
        }
    }
}