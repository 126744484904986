@import '../../../../../../theme/variables.scss';

.payments_wrapper {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-divider {
        margin: 15px 0;
    }

    .pricing_item {
        display: flex;
        flex-direction: column;
        gap: 10px;

        strong {
            font-size: 16px;
            font-weight: 600;
        }
    }

    .quentity {
        display: flex;
        align-items: center;
        gap: 20px;

        h5,
        p {
            margin: 0;
            font-size: 16px;
        }

        h5 {
            font-weight: 600;
            width: 100px;
        }
    }

    .total {
        display: flex;
        flex-direction: column;
        gap: 10px;

        div {
            display: flex;
            align-items: center;
        }

        h4,
        p {
            margin: 0;
            font-size: 16px;
        }

        h4 {
            font-weight: 600;
            width: 200px;
        }

        .grand_total {
            display: flex;
            align-items: end;
            gap: 10px;
            color: $primary-color;

            h5 {
                margin: 0;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 4px;
            }

            h6 {
                margin: 0;
                font-size: 36px;
                font-weight: 600;
            }
        }
    }
}

.tour_book_instalments {
    .installment {
        padding: 10px;
        background-color: $background-color;
        border-radius: 10px;

        .anticon {
            margin-top: 0;
        }
    }

    .service {
        display: flex;
        align-items: center;

        h4,
        p {
            margin: 0;
            font-size: 16px;
        }
    }

    .price {
        display: flex;
        align-items: center;

        h4,
        p {
            margin: 10px 0 0 0;
            font-size: 16px;
            color: $primary-color;
        }

        h4 {
            font-size: 18px;
            font-weight: 600;
            margin-right: 10px;
        }
    }
}

.tour_booking_modal {
    .bank_details {
        display: flex;
        flex-direction: column;
        gap: 5px;

        p {
            font-size: 16px;
            color: $primary-color;
            margin: 0;

            strong {
                font-weight: 600;
            }
        }
    }
}