@import '../../../../theme/variables.scss';

.messages_inbox {
    .messages {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .loading_container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40vh;
            width: 100%;
        }

        .message {
            background-color: #fff;
            padding: 15px 20px;
            border-radius: 10px;

            h3 {
                font-size: 1.2rem;
                font-weight: 600;
                color: $primary-color;
                margin: 0;
            }

            .time {
                font-size: 13px;
                color: $gray-color;
            }

            .message__content {
                margin-top: 20px;
            }
        }

        .read {
            background-color: #f5f5f5;
        }

        .message__footer {
            margin-top: 20px;
        }
    }
}