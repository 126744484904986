@import '../../../../../../../theme/variables.scss';

.booking_details {
    .slider {
        margin: 15px 0 20px 0;

        .react-multiple-carousel__arrow {
            z-index: 10 !important;
        }

        .custom-dot-list-style {
            display: none;
        }

        .destination_card {
            margin: 0 5px;
            border: none;
            cursor: pointer;
        }

        .card_image {
            height: 170px;
            border-radius: 10px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }

        .card_body {
            padding: 0;
        }

        .card_title {
            margin: 0;
            margin-top: 10px;
            font-size: 18px;
            font-weight: 600;
            color: $dark-color;
            padding: 0;
        }
    }
}