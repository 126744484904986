@import '../../../../../theme/variables.scss';

.custom_tour_start_planing {

    .custom_tour_start_planing__form {

        .form_item_label {
            width: 130px;
            text-align: start;
        }

        .fav_selector {
            margin: 0;

            .icon-wrapper {
                position: relative;
                padding: 0 30px;
            }

            .icon-wrapper .anticon {
                position: absolute;
                top: -2px;
                width: 18px;
                height: 18px;
                font-size: 20px;
                line-height: 1;
            }

            .icon-wrapper .anticon:first-child {
                inset-inline-start: 0;
            }

            .icon-wrapper .anticon:last-child {
                inset-inline-end: 0;
            }
        }
    }

    .details_selector {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .review_section {
            display: flex;
            gap: 20px;
            align-items: center;

            h3 {
                margin: 0;
                font-size: 16px;
                color: $primary-color;
                font-weight: 600;
            }

            p {
                margin: 0;
                font-size: 16px;
                color: $dark-color;
                font-weight: 400;
            }

            .tags {
                display: flex;
                align-items: center;
                gap: 10px;
                flex-wrap: wrap;

                span {
                    padding: 8px 16px;
                    background-color: $primary-color-light;
                    border-radius: 20px;
                    color: $primary-color;
                    font-size: 14px;
                    font-weight: 500;
                    cursor: pointer;

                    &:hover {
                        background-color: $primary-color;
                        color: #fff;
                    }
                }
            }
        }

        .review_section_fav {
            margin-top: 20px;

            h3 {
                margin: 0;
                font-size: 20px;
                color: $primary-color;
                font-weight: 600;
            }

            .tags {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 10px;

                .item {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    h4 {
                        margin: 0;
                        font-size: 16px;
                        color: $dark-color;
                        font-weight: 400;
                        width: 140px;
                    }

                    .ant-slider {
                        width: 200px;
                    }
                }
            }
        }
    }

    .agrreement_selector {
        .agreemnet {
            a {
                color: $light-2-color;
                text-decoration: none;

                &:hover {
                    color: $primary-color-light;
                }
            }
        }
    }
}