@import '../../../../../theme/variables.scss';

.bookTour {
    @media (max-width: 1200px) {
        margin-top: 60px;
    }

    @media (max-width: 900px) {
        margin-top: 0;
    }

    .content_body_main {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        gap: 20px;
        text-align: start;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 10px;
        }

        p,
        h2 {
            margin: 0;
        }

        .contents {
            width: 70%;

            @media (max-width: 768px) {
                width: 100%;
            }

            .booking_input_section {
                padding: 20px;
                background-color: #fff;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: start;
            }
        }

        .booking_pricing {
            width: 30%;
            padding: 20px;
            background-color: #fff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
            align-items: start;
            font-size: 14px;
            gap: 10px;

            @media (max-width: 768px) {
                width: 100%;
            }

            .ant-divider {
                margin: 10px 0;
            }

            .pricing_item {
                display: flex;
                gap: 10px;
                flex-direction: column;
            }

            .date {
                margin-top: 8px;
            }

            .quentity {

                h5,
                p {
                    margin: 0;
                    font-size: 14px;
                }

                width: 100%;

                div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;
                    margin-top: 10px;
                    font-size: 16px;
                    color: #333;
                    font-weight: 600;
                }
            }

            .total {
                width: 100%;

                span {
                    font-size: 12px;
                    color: gray;
                }

                div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;
                    margin-top: 10px;
                    font-size: 16px;
                    color: #333;
                    font-weight: 600;

                    h4 {
                        font-size: 16px;
                        color: #333;
                    }

                    h5 {
                        font-size: 20px;
                        color: $light-1-color;
                    }

                    h6 {
                        font-size: 25px;
                        color: $primary-color;
                        font-weight: 700;
                    }
                }
            }

            .actions {
                width: 100%;
                display: flex;
                flex-direction: column;

                .ant-btn {
                    margin-top: 10px;
                    width: 100%;
                    height: 50px;
                    font-size: 16px;
                    font-weight: 600;
                    border-radius: 10px;
                }

                .next {
                    background-color: $primary-color-light;
                    color: $primary-color;
                    font-weight: 600;
                    border: none;

                    &:hover {
                        background-color: $primary-color;
                        color: #fff;
                    }
                }

                .prev {
                    border: 1px solid $light-1-color;
                    color: $light-1-color;

                    &:hover {
                        border: 1px solid $primary-color;
                        color: $primary-color;
                    }
                }
            }
        }
    }
}