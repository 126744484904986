@import '../../../../theme/variables.scss';

.evenet_plan {
    .content_body {
        margin: 20px 0 30px 0;

        p {
            font-size: 16px;
            margin: 0;
            color: $primary-color;
            line-height: 1.5;
        }
    }

    .content_body_main {
        background-color: #fff;
        min-height: 100vh;
    }

    .support__container {
        .quill {
            border: none;

            .ql-toolbar {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            .ql-container {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                min-height: 200px;
                padding: 20px;
            }
        }
    }
}