.top_margin_main {
    padding-top: 80px;

    @media (max-width: 1024px) {
        padding-top: 10px;
    }
}

.section {
    margin: 30px 0;

    @media (max-width: 768px) {
        margin: 20px 0;
    }
}

.loading_page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.top_padding_container {
    padding-top: 80px;

    @media (max-width: 768px) {
        padding-top: 0;
    }
}