@import '../../../../../../theme/variables.scss';

.booking_input_section {
    margin-bottom: 20px;

    .note_Sub {
        color: $gray-color;
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
    }

    .calander_wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .note {
            margin: 5px 0 10px 0;
            color: $gray-color;
            font-size: 14px;

            span {
                color: $red_orange_color;
                font-weight: 500;
            }
        }
    }

    .wrapper {
        width: 100%;

        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            width: 100%;

            span {
                display: flex;
                gap: 10px;

                h3,
                h4 {
                    font-size: 16px;
                    color: $primary-color;
                    font-weight: 600;
                }

                h4 {
                    color: $gray-color;
                    font-weight: 500;
                }
            }
        }

        .room_type {
            margin-top: 15px;
            width: 100%;
            gap: 20px;
            background-color: $background-color;
            padding: 10px 20px;
            border-radius: 10px;
            transition: all 0.3s ease;

            @media (max-width: 900px) {
                padding: 10px;
            }

            .inner_type {
                display: flex;
                flex: 1;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }

            .flex {
                display: flex;
                align-items: center;
                gap: 10px;

                .image {
                    width: 40%;

                    @media (max-width: 600px) {
                        display: none;
                    }

                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .texts {
                    width: 60%;

                    @media (max-width: 600px) {
                        width: 100%;
                        margin-right: 10px;
                    }
                }
            }

            &:hover {
                background-color: #efefef;
            }

            span {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media (max-width: 900px) {
                    flex-direction: row !important;
                }
            }

            .ant-radio {
                width: 18px;
            }

            h5 {
                font-size: 16px;
                color: $primary-color;
                font-weight: 600;
            }

            p {
                font-size: 14px;
                color: $gray-color;
                font-weight: 500;
            }

            h4 {
                font-size: 16px;
                color: $primary-color;
                font-weight: 600;
            }

            .left {
                width: 70%;

                @media (max-width: 900px) {
                    width: 100%;
                }
            }

            .right {
                display: flex;
                width: 35%;
                align-items: end;
                flex-direction: column;

                @media (max-width: 900px) {
                    width: 100%;
                }
            }
        }

        .disabled {
            background-color: #f5f5f54d;
            cursor: not-allowed;

            &:hover {
                background-color: #f5f5f54d;
            }
        }
    }
}