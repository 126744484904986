@import '../../../../../theme/variables.scss';

.blog_container {
    min-height: 90vh;

    .loading_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        height: 50vh;
    }

    .blog {
        .blog_header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .blog_meta {
                .author {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .author_image {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        border: 1px solid $primary-color-light;
                        padding: 2px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }

                    .author_name {
                        h3 {
                            margin: 0;
                            font-size: 18px;
                            color: $dark-color;
                        }

                        p {
                            margin: 0;
                            font-size: 12px;
                            color: $gray-color;
                        }
                    }
                }
            }

            .actions {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }

        .blog_short {
            margin-top: 20px;

            .tags {
                margin: 20px 0;

                .ant-tag {
                    font-size: 14px;
                    padding: 5px 15px;
                }
            }
        }

        .tagged_destinations {
            .ant-tag {
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }

        .blog_images {
            margin-top: 20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;

            @media (max-width: 768px) {
                grid-template-columns: repeat(2, 1fr);
            }

            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
                border-radius: 10px;

                @media (max-width: 768px) {
                    height: 200px;
                }
            }
        }
    }
}