@import '../../../../../../../theme/variables.scss';

.custom_tour_chat {
    h1 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        color: $gray-color;
    }

    .chat_body {
        .chat_messages {
            height: 60vh;
            overflow-y: auto;
            padding: 10px;
            background-color: #fff;
            border-radius: 10px;
            margin-top: 10px;
            display: flex;
            flex-direction: column;

            .no_messages {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            .chat_message {
                margin-bottom: 10px;
                padding: 10px;
                border-radius: 10px;
                background-color: #fff;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.049);
                width: 70%;
            }

            .admin_chat_message {
                align-self: start;
                background-color: #c5f3f171;
            }

            .user_chat_message {
                align-self: end;
                background-color: #afcfdc71;
            }

            .chat_message_sender {
                display: flex;
                align-items: center;
                gap: 10px;

                h4,
                p {
                    margin: 0;
                }

                h4 {
                    font-size: 16px;
                    font-weight: 600;
                    color: $primary-color;
                }
            }

            .ant-btn {
                padding: 0;
            }
        }

        .chat_message_input {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            border-radius: 10px;
            padding: 10px;
            background-color: #fff;

            .ql-toolbar {
                display: none;
            }

            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }
}