@import '../../../../theme/variables.scss';

.destination {
    .hero__content_header {
        display: flex;
        gap: 50px;
        align-items: center;
        justify-content: space-between;

        .actions {
            display: flex;
            gap: 18px;

            .button {
                padding: 0;
                font-size: 30px;
            }

            @media (max-width: 768px) {
                align-items: center;

                .button {
                    font-size: 24px;
                }
            }

            .dark {
                color: $primary-color;

                &:hover {
                    color: $light-2-color;
                }

                &.active {
                    color: $light-1-color;
                }
            }
        }
    }

    .image_gallery {
        display: flex;
        gap: 20px;
        margin-top: 20px;
        height: 500px;
        position: relative;

        @media (max-width: 1024px) {
            height: 300px;
        }

        .total {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: rgba(0, 0, 0, 0.6);
            color: $white-color;
            padding: 5px 10px;
            border-radius: 5px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 10px;
            cursor: pointer;
        }

        .left {
            width: 70%;
        }

        .right {
            width: 30%;

            .images_container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                height: 100%;

                span {
                    height: 50%;

                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .more_images {
                display: none;
            }
        }

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 5px;
            margin-top: 10px;
            height: auto;

            .total {
                bottom: 5px;
                right: 5px;
                font-size: 12px;
            }

            img {
                border-radius: 5px;
            }

            .left {
                width: 100%;
            }

            .right {
                width: 100%;

                .images_container {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;

                    span {
                        height: 70px;
                        width: 100%;

                        img {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .about_destination {
        display: flex;
        gap: 20px;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 20px;
        }

        .left {
            width: 70%;

            @media (max-width: 768px) {
                width: 100%;
            }

            .description_short {
                position: relative;
                margin: 20px 0;

                @media (max-width: 768px) {
                    margin: 20px 0;
                }

                .read_more {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background-color: rgba(0, 0, 0, 0.6);
                    color: $white-color;
                    padding: 5px 10px;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.8);
                    }
                }
            }

            .weather_district {
                display: flex;
                gap: 20px;

                .item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 26px;
                    color: $dark-color;

                    p {
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                        font-weight: 500;
                    }

                    @media (max-width: 768px) {
                        font-size: 20px;
                        gap: 5px;

                        p {
                            font-size: 14px;
                        }
                    }
                }

                @media (max-width: 768px) {
                    gap: 10px;
                    flex-direction: column;
                }
            }

            .tags {
                margin: 30px 0 0 0;
                display: flex;
                gap: 6px;
                flex-wrap: wrap;

                .ant-tag {
                    font-size: 16px;
                    font-weight: 500;
                    padding: 8px 15px;
                    color: $white-color;
                    background-color: $primary-color;
                    border-radius: 5px;
                    border: none;

                    .anticon {
                        display: none;
                    }
                }

                .must_visit {
                    background-color: $red_orange_color;
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .anticon {
                        display: block;
                    }
                }

                @media (max-width: 768px) {
                    margin: 20px 0 0 0;

                    .ant-tag {
                        font-size: 14px;
                        padding: 6px 10px;
                    }
                }
            }
        }

        .right {
            width: 30%;
            height: auto;

            @media (max-width: 768px) {
                width: 100%;
                height: 300px;
            }

            iframe {
                width: 100%;
                height: 100%;
                border: none;
                border-radius: 10px;
                background-color: #dbdbdba7;
            }
        }
    }

    .more_about_destination {
        margin-top: 50px;

        @media (max-width: 768px) {
            margin-top: 30px;
        }

        .more_about_destination__content {
            margin: 20px 0;
            position: relative;

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, $background-color 100%);
                color: $white-color;
                display: flex;
                justify-content: center;
                align-items: end;
                opacity: 1;
                transition: all 0.3s ease;
                cursor: pointer;
                z-index: 1;
            }

            .open {
                display: none;
            }

            .more {
                padding: 0;
                font-size: 17px;
                color: $light-1-color;
                font-weight: 500;
                margin-bottom: 10px;
                cursor: pointer;

                &:hover {
                    color: $primary-color;
                }

                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }

            .less {
                display: block;
                margin: 0;
                padding: 10px 0;
                font-size: 16px;
                font-weight: 700;
            }
        }
    }

    .attractions {
        margin-top: 50px;

        .attractions__content {
            margin-top: 20px;
        }

        .pagination_container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }

    .activities {
        margin-top: 20px;

        .attractions__content {
            margin-top: 20px;
        }

        .pagination_container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }

    .videos {
        margin-top: 20px;
        background-color: $primary-color;
        border-radius: 10px;
        overflow: hidden;

        div {
            height: 500px;
            backdrop-filter: blur(20px);

            @media (max-width: 768px) {
                height: 300px;
            }

            video {
                height: 100%;
                border-radius: 10px;
                margin: auto;
                width: 100%;
            }
        }
    }

    .stays {
        margin-top: 30px;

        .coming_soon {
            border: 1px solid $light-gray-color;
            border-radius: 10px;
            margin-top: 10px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                font-weight: 500;
                margin: 0;
                font-size: 20px;
                color: $light-gray-color;
            }
        }
    }

    .events {
        margin-top: 20px;

        .attractions__content {
            margin-top: 20px;
        }

        .pagination_container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }

    .blogs {
        margin-top: 30px;

        .package__card {
            min-height: 460px;

            .author {
                display: flex;
                align-items: center;
                gap: 10px;

                .author_image {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid $primary-color-light;
                    padding: 2px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                .author_name {
                    h3 {
                        margin: 0;
                        font-size: 18px;
                        color: $dark-color;
                    }

                    p {
                        margin: 0;
                        font-size: 12px;
                        color: $gray-color;
                    }
                }
            }
        }
    }
}