.user_toru_accommodations {
    .booking__accommodations__card {
        background-color: #fff;
        margin-bottom: 10px;
        padding: 15px 20px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .booking__loading_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30vh;
    }
}