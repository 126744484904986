@import '../../../../../theme/variables.scss';

.bookAccommodation {
    margin-top: 80px;

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }

    .loading__container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60vh;
    }

    .bookAccommodation {
        &__container {
            .ant-descriptions {
                margin: 20px auto;

                @media screen and (max-width: 768px) {

                    .ant-descriptions-view {
                        >table {
                            display: block;

                            tbody {
                                display: block;

                                th {
                                    display: block;
                                    width: 100%;
                                }

                                tr {
                                    display: block;
                                    margin-bottom: 10px;
                                    width: 100%;

                                    td {
                                        display: block;
                                        width: 100%;
                                        padding: 12px;

                                        &.ant-descriptions-item-label {
                                            background-color: #fafafa;
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .ant-tag {
                        margin: 4px;
                    }
                }
            }
        }
    }

    .bookAccommodation__container {
        margin-top: 20px;

        .paragraph {
            margin-top: 10px;
        }

        .booking-form {
            margin: 30px auto;
            padding: 40px;
            background: #fff;
            border-radius: 15px;
            box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);

            .form-row-in {
                display: flex;
                gap: 2px;
                margin-bottom: 24px;

                @media (max-width: 768px) {
                    flex-direction: column;
                    gap: 0;
                }
            }

            .form-row {
                display: flex;
                gap: 24px;
                margin-bottom: 24px;
                width: 100%;

                .ant-form-item {
                    margin-bottom: 24px;
                    width: 100%;
                }

                @media (max-width: 768px) {
                    flex-direction: column;
                    gap: 0;
                }

                .form-col {
                    flex: 1;
                }
            }

            .ant-input,
            .ant-input-number,
            .ant-picker {
                height: 45px;
                border-radius: 8px;
                border: 1px solid #d9d9d9;
                display: flex;
                align-items: center;
                width: 100%;

                &:hover,
                &:focus {
                    border-color: $primary-color;
                    box-shadow: 0 0 0 2px rgba($primary-color, 0.1);
                }
            }

            .ant-input-number {
                width: 100%;
            }

            .date-picker {
                width: 100%;
            }

            .meals-section {
                background: #f8f9fa;
                padding: 24px;
                border-radius: 12px;
                margin: 24px 0;

                h4 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 16px;
                    color: #2c3e50;
                }

                .ant-checkbox-wrapper {
                    margin: 8px 16px 8px 0;
                }

                .ant-radio-group {
                    margin-top: 16px;
                }
            }

            .ant-btn-primary {
                height: 45px;
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                border-radius: 8px;
                margin-top: 16px;
            }

            textarea.ant-input {
                min-height: 120px;
                border-radius: 8px;
            }

            .ant-form-item-label>label {
                font-weight: 500;
                color: #2c3e50;
            }
        }
    }
}