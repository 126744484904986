@import '../../../../../../../theme/variables.scss';

.tour_booking_itinerary {
    .booking__accommodations__card {
        background-color: #fff;
        margin-bottom: 10px;
        padding: 15px 20px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .booking__loading_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30vh;
    }

    .tour_agree_changes {
        .instruction {
            font-size: 16px;
            color: $gray-color;
            margin: 0;
        }

        .agreee {
            display: flex;
            gap: 10px;
            margin-top: 10px;
            align-items: center;
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;

            p {
                color: $gray-color;
                margin: 0;
            }

            .ant-btn {
                background-color: $success_color;
                color: #fff;
                border: none;
                border-radius: 5px;
                padding: 0 15px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 600;
                cursor: pointer;

                &:hover {
                    background-color: $primary-color-light;
                }
            }
        }
    }

    .changes {
        position: relative;

        .ant-form {
            margin-top: 15px;
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;

            .ant-form-item {
                margin-bottom: 10px;
            }
        }

        .reqests {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 10px;
            padding: 20px;
            border-radius: 10px;

            h4 {
                font-size: 18px;
                font-weight: 600;
                color: $primary-color;
                margin: 0 0 5px 0;
            }

            P {
                font-size: 14px;
                color: $gray-color;
                margin: 0;
                line-height: 2;
            }

            .ant-divider {
                margin: 10px 0;
            }
        }

        .user_agreed {
            padding: 0px;
        }
    }


    .itinerary {
        margin-top: 15px;

        .itinerary_step {
            .ant-steps-icon-dot {
                background: none !important;
                border: 2px solid $primary-color-light;
                width: 15px;
                height: 15px;
            }

            .ant-steps-item-tail {
                top: 15px;
                left: 3px;
            }

            .ant-steps-item-container {

                .ant-steps-item-content {
                    background-color: #fff;
                    margin-bottom: 10px;
                    border-radius: 10px;

                    .ant-steps-item-title {
                        width: 100%;
                        padding: 0 10px;
                    }

                    .ant-collapse-header {
                        padding: 12px 16px 0 16px;

                        .ant-collapse-header-text {
                            span {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                h2 {
                                    font-size: 16px;
                                    margin: 0;
                                    font-weight: 700;
                                    color: $gray-color;
                                }

                                p {
                                    font-size: 16px;
                                    margin: 0;
                                    color: $primary-color;
                                    font-weight: 600;
                                }

                                @media (max-width: 768px) {
                                    h2 {
                                        font-size: 14px;
                                    }

                                    p {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }

                    .itinerary_collapse {
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: $gray-color;
                            margin: 0;
                        }

                        h3 {
                            font-size: 18px;
                            font-weight: 600;
                            color: $primary-color;
                            margin: 10px 0;
                            cursor: pointer;

                            &:hover {
                                color: $light-1-color;
                            }
                        }

                        .day_description {
                            margin: 0;
                        }

                        h4 {
                            font-size: 18px;
                            font-weight: 600;
                            color: $primary-color;
                            margin: 0 0 10px 0;
                        }

                        @media (max-width: 768px) {
                            h2 {
                                font-size: 18px;
                            }

                            h3 {
                                font-size: 16px;
                            }

                            h4 {
                                font-size: 16px;
                            }
                        }

                        .main__destination {
                            display: flex;
                            margin-top: 10px;
                            gap: 20px;
                            align-items: start;

                            .left {
                                order: 1;

                                img {
                                    width: 300px;
                                    height: 160px;
                                    object-fit: cover;
                                    border-radius: 10px;
                                }
                            }

                            .right {
                                order: 2;

                                .ql-editor {
                                    padding: 0;
                                    background-color: none;
                                    all: unset;

                                    p {
                                        font-size: 14px;
                                        line-height: 1.5;
                                        color: $dark-color;

                                        @media (max-width: 768px) {
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }

                            @media (max-width: 576px) {
                                flex-direction: column;
                                gap: 10px;

                                .left {
                                    order: 2;

                                    img {
                                        width: 100%;
                                        height: 150px;
                                    }
                                }

                                .right {
                                    order: 1;
                                }
                            }
                        }

                        .experience {
                            .note {
                                margin-top: 10px;

                                span {
                                    color: $red_orange_color;
                                    font-weight: 600;
                                }
                            }

                            .itemss {
                                margin-top: 15px;

                                .item_iti_dis {
                                    display: flex;
                                    gap: 20px;

                                    .left {
                                        order: 1;

                                        img {
                                            width: 300px;
                                            height: 160px;
                                            object-fit: cover;
                                            border-radius: 10px;
                                        }
                                    }

                                    .right {
                                        order: 2;

                                        p {
                                            margin: 5px 0;
                                        }

                                        .ql-editor {
                                            padding: 0;
                                            background-color: none;
                                            all: unset;

                                            p {
                                                font-size: 14px;
                                                line-height: 1.5;
                                                color: $dark-color;

                                                @media (max-width: 768px) {
                                                    font-size: 14px;
                                                }
                                            }
                                        }
                                    }

                                    @media (max-width: 576px) {
                                        flex-direction: column;
                                        gap: 10px;

                                        .left {
                                            order: 2;

                                            img {
                                                width: 100%;
                                                height: 150px;
                                            }
                                        }

                                        .right {
                                            order: 1;

                                            p {
                                                margin: 5px 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}