@import '../../../../../../../theme/variables.scss';

.acc-booking-detals {
    .payment_splip_submit {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        width: 100%;

        .ant-upload-wrapper {
            width: 100%;
        }

        .payment_due_date {
            display: flex;
            gap: 10px;
            align-items: center;
            margin: 10px 0 0 0;
            font-size: 18px;
            color: $primary-color;
        }
    }
}