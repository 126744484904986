@import '../../../theme/variables.scss';

.home {
    background-color: transparent;

    .main__hero {
        text-align: center;
        background-color: transparent;

        .hero__background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -2;

            img {
                width: 100%;
                object-fit: cover;
                height: 140vh;

                @media (max-width: 1568px) {
                    height: 142vh;
                }
            }
        }

        .section__1 {
            margin-top: 200px;

            .custom-shape-divider-top-1724082403 {
                bottom: 0;
                left: 0;
                width: 100%;
                overflow: hidden;
                line-height: 0;
                transform: rotate(180deg);
            }

            .custom-shape-divider-top-1724082403 svg {
                position: relative;
                display: block;
                width: calc(163% + 1.3px);
                height: 302px;

                @media (max-width: 1200px) {
                    height: 200px;
                }

                @media (max-width: 700px) {
                    height: 150px;
                }

                @media (max-width: 500px) {
                    height: 100px;
                }
            }

            .custom-shape-divider-top-1724082403 .shape-fill {
                fill: $background-color;
            }

            .hero__top {
                margin-top: 165px;

                .title {
                    color: $primary-color;
                    font-size: 96px;
                    font-weight: 700;
                    margin: 0;
                    margin-bottom: 20px;
                    text-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
                }

                .hero_search {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    @media (max-width: 940px) {
                        display: none;
                    }

                    .ant-tabs-top>.ant-tabs-nav::before,
                    :where(.css-dev-only-do-not-override-4po8uv).ant-tabs-top>div>.ant-tabs-nav::before {
                        display: none;
                    }

                    .ant-tabs-ink-bar {
                        background-color: $dark-gray-color;
                        height: 2.5px;
                        transform: translateY(-8px) translateX(-50%) !important;
                    }

                    .ant-tabs-nav-list {
                        .ant-tabs-tab {
                            .ant-tabs-tab-btn {
                                color: $dark-gray-color;
                                font-size: 16px;
                                font-weight: 600;

                                @media (max-width: 1200px) {
                                    font-size: 12px;
                                    color: $dark-gray-color;
                                    font-weight: 600;
                                }
                            }
                        }
                    }

                    .hero__search__button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;

                        .ant-select {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 500px !important;

                            @media (max-width: 1200px) {
                                width: 320px !important;
                            }

                            .search__input {
                                width: 500px;
                                height: 50px;
                                border-radius: 100px;
                                padding: 0 20px;
                                border: none;
                                background: rgba(255, 255, 255, 0.6);
                                color: $gray-color;
                                font-size: 16px;
                                font-weight: 500;

                                @media (max-width: 1200px) {
                                    width: 320px;
                                }
                            }
                        }

                        .serach_btn {
                            color: $primary-color;
                            font-size: 16px;
                            font-weight: 600;
                            background-color: $primary-color-light;
                            border-radius: 100px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 24px 30px;

                            &:hover {
                                background-color: $primary-color !important;
                                color: $white-color;
                            }
                        }
                    }
                }

                .mobile__hero_search {
                    display: none;
                    padding: 20px 20px 0 20px;

                    @media (max-width: 940px) {
                        display: block;
                    }

                    .mobile__hero_search__inner {
                        display: flex;
                        align-items: start;
                        justify-content: center;
                        flex-direction: column;
                        gap: 10px;
                        width: 100%;
                        background-color: #ffffff6d;
                        backdrop-filter: blur(10px);
                        padding: 10px;
                        border-radius: 20px;
                        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);

                        .ant-divider {
                            margin: 10px 0;
                        }

                        .search__select {
                            .ant-select-selector {
                                border: none !important;
                                background-color: transparent;
                                color: $gray-color;
                                font-size: 16px;
                                font-weight: 600 !important;
                                width: 100%;
                                box-shadow: none !important;
                                text-align: start;

                                .ant-select-selection-item {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                }
                            }
                        }

                        .ant-select {
                            display: flex;
                            width: 100%;

                            .search__input {
                                border: none;
                                color: $gray-color;
                                background-color: transparent;
                                font-size: 16px;
                                font-weight: 500;
                                width: 100%;
                                box-shadow: none !important;

                                ::placeholder {
                                    color: $gray-color;
                                }
                            }
                        }

                        .serach_btn {
                            color: $primary-color;
                            font-size: 16px;
                            font-weight: 600;
                            background-color: $primary-color-light;
                            border-radius: 100px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 24px;
                            width: 100%;

                            &:hover {
                                background-color: $primary-color !important;
                                color: $white-color;
                            }
                        }

                    }
                }
            }

            .hero__top__down {
                margin-top: 200px;
                display: flex;
                justify-content: space-between;

                .social__media {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    margin: $navbar_padding;
                    gap: 20px;
                    margin-top: 40px;

                    a {
                        color: $white-color;
                        font-size: 25px;
                        transition: all 0.3s ease;

                        &:hover {
                            transform: scale(1.1);
                            color: $primary-color-light;
                        }
                    }
                }

                .hero__top__down__right {
                    display: flex;
                    align-items: end;
                    width: 85%;

                    .small_cards_container {
                        display: flex;
                        align-items: start;
                        justify-content: space-between;
                        background: rgba(0, 0, 0, 0.60);
                        padding: 10px 20px;

                        .custom__card {
                            text-align: start;
                            padding: 20px;
                            flex: 1;

                            h3 {
                                color: $primary-color-light;
                                font-size: 16px;
                                margin: 0;
                                font-weight: 500;
                                margin-bottom: 6px;
                            }

                            h2 {
                                color: $white-color;
                                font-size: 16px;
                                font-weight: 600;
                                margin: 0;
                                height: 50px;
                            }

                            p {
                                color: $medium-gray-color;
                                font-size: 14px;
                                margin: 0;
                                line-height: 1.5;
                            }
                        }
                    }

                    .big__card {
                        position: relative;

                        h2 {
                            position: absolute;
                            color: $white-color;
                            font-size: 16px;
                            font-weight: 600;
                            margin: 0;
                            height: 45px;
                            top: 20px;
                            left: 20px;
                        }

                        video {
                            width: 420px;
                            display: block;
                        }
                    }

                }
            }
        }

        .hero__bottom {
            width: 100%;
            background-color: $background-color;

            .hero__bottom__content {
                z-index: 20;
                padding: 0 $content_padding;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media (max-width: 1200px) {
                    flex-direction: column;
                    align-items: center;
                    padding: $content_padding_mobile;
                }

                @media (max-width: 700px) {
                    background-color: $background-color;
                }

                .hero__bottom__content__left {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    text-align: start;
                    width: 60%;
                    margin-bottom: 0px;

                    h1 {
                        font-size: 64px;
                        font-weight: 700;
                        color: $primary-color;
                    }

                    h2 {
                        font-family: "Meow Script";
                        font-size: 96px;
                        font-style: normal;
                        font-weight: 400;
                        color: $light-1-color;
                        margin-bottom: 30px;
                        width: 70%;
                        text-align: end;
                    }

                    p {
                        font-size: 18px;
                        line-height: 1.5;
                    }

                    .hero__bottom__content__left__button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        margin-top: 45px;
                    }

                    @media (max-width: 1568px) {
                        margin-bottom: 50px;
                    }

                    @media (max-width: 1280px) {
                        margin-bottom: 20px;
                    }

                    @media (max-width: 1200px) {
                        width: 100%;
                        text-align: center;
                        text-align: center;

                        h1,
                        h2,
                        p {
                            width: 100%;
                        }

                        h2 {
                            font-size: 60px;
                            margin-bottom: 20px;
                        }

                        .hero__bottom__content__left__button {
                            justify-content: center;
                            width: 100%;
                        }
                    }

                    @media (max-width: 768px) {
                        width: 100%;
                        text-align: center;

                        h1 {
                            font-size: 50px;
                        }

                        h2 {
                            font-size: 40px;
                        }

                        p {
                            font-size: 14px;
                        }

                        .hero__bottom__content__left__button {
                            margin-top: 20px;
                        }
                    }
                }

                .hero__bottom__content__right {
                    width: 40%;
                    transform: translateY(-70px);

                    @media (max-width: 1568px) {
                        width: 46%;
                        transform: translateY(-60px);
                    }

                    @media (max-width: 1280px) {
                        width: 45%;
                        transform: translateY(-50px);
                    }

                    @media (max-width: 1200px) {
                        display: none;
                    }

                    img {
                        width: 100%;
                    }

                }
            }
        }
    }

    .hero__text {
        background: rgba(0, 0, 0, 0.5);
        padding: 1rem;
        border-radius: 0.5rem;
    }


    @media (max-width: 1600px) {
        .main__hero {
            .section__1 {

                .hero__top__down {
                    margin-top: 100px;
                }
            }
        }
    }

    @media (max-width: 1400px) {
        .main__hero {
            .section__1 {
                margin-top: 150px;

                .hero__top {
                    margin-top: 0px;

                    .title {
                        font-size: 85px;
                    }
                }

                .hero__top__down {
                    margin-top: 60px;

                    .hero__top__down__right {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .main__hero {
            .hero__background {
                img {
                    width: 100%;
                    object-fit: cover;
                    height: 140vh;
                }
            }

            .section__1 {
                margin-top: 100px;

                .hero__top {
                    margin-top: 0px;

                    .title {
                        font-size: 70px;
                        margin-bottom: 10px;
                    }

                    .hero__search__button {
                        gap: 10px;

                        .ant-select {
                            width: 300px !important;

                            .search__input {
                                width: 100px;
                            }
                        }

                        .serach_btn {
                            color: $primary-color;
                            font-size: 16px;
                            font-weight: 600;
                            background-color: $primary-color-light;
                            border-radius: 100px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 24px 30px;

                            &:hover {
                                background-color: $primary-color !important;
                                color: $white-color;
                            }
                        }
                    }
                }

                .hero__top__down {
                    margin-top: 60px;

                    .social__media {
                        margin: 30px;
                        gap: 20px;
                        margin-top: 70px;

                        a {
                            color: $white-color;
                            font-size: 16px;
                        }
                    }

                    .hero__top__down__right {
                        width: 100% !important;

                        .small_cards_container {
                            padding: 10px;

                            .custom__card {
                                padding: 10px;

                                h3 {
                                    font-size: 14px;
                                }

                                h2 {
                                    font-size: 14px;
                                }

                                p {
                                    font-size: 12px;
                                }
                            }
                        }

                        .big__card {
                            h2 {
                                font-size: 14px;
                            }

                            video {
                                width: 350px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 920px) {
        .main__hero {
            .hero__background {
                img {
                    width: 100%;
                    object-fit: cover;
                }
            }

            .section__1 {
                width: 100%;

                .hero__top {
                    margin-top: 120px;

                    .hero_search {

                        .search__select {
                            margin-bottom: 20px;
                        }

                        .ant-tabs-nav-list {
                            width: 400px;

                            .ant-tabs-tab {
                                .ant-tabs-tab-btn {
                                    color: $dark-gray-color;
                                    font-size: 16px;
                                    font-weight: 600;
                                }
                            }
                        }

                        .hero__search__button {
                            flex-direction: column;

                            .ant-select {
                                width: 400px !important;

                                .search__input {
                                    width: 400px;
                                }
                            }

                            .serach_btn {
                                display: none;
                            }
                        }
                    }
                }

                .hero__top__down {
                    margin-top: 50px;
                    flex-direction: column;

                    .social__media {
                        flex-direction: row;
                        gap: 20px;
                        margin-top: 20px;
                        order: 2;

                        a {
                            color: $white-color;
                            font-size: 16px;
                        }
                    }

                    .hero__top__down__right {
                        order: 1;
                        gap: 20px;
                        padding: 20px;

                        .small_cards_container {
                            position: relative;
                            width: 100%;
                            height: 150px;
                            border-radius: 10px;
                            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

                            .custom__card {
                                position: absolute;
                                width: 100%;
                                padding: 10px;
                                text-align: center;
                                left: 0;
                                opacity: 0;
                                animation: showHide 6s infinite linear;
                                animation-timing-function: ease-in-out;

                                &:nth-child(1) {
                                    animation-delay: 0s;
                                }

                                &:nth-child(2) {
                                    animation-delay: 2s;
                                }

                                &:nth-child(3) {
                                    animation-delay: 4s;
                                }

                                p {
                                    display: block !important;
                                }

                                h2 {
                                    height: 30px;
                                }
                            }
                        }

                        .big__card {
                            width: 100%;

                            video {
                                border-radius: 10px;
                                width: 100% !important;
                                display: block;
                                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                            }
                        }
                    }
                }

                @keyframes showHide {

                    0%,
                    33.33% {
                        opacity: 1;
                    }

                    33.34%,
                    100% {
                        opacity: 0;
                    }
                }
            }
        }
    }

    @media (max-width: 700px) {
        .main__hero {
            .hero__background {
                img {
                    width: 100%;
                    object-fit: cover;
                }
            }

            .section__1 {
                width: 100%;
                margin-top: 70px;

                .hero__top {
                    margin: 0;

                    .title {
                        font-size: 60px;
                    }

                    .hero_search {
                        .ant-tabs-nav-list {
                            width: 300px;

                            .ant-tabs-tab {
                                margin: 0 0 0 20px;

                                .ant-tabs-tab-btn {
                                    color: $dark-gray-color;
                                    font-size: 16px;
                                    font-weight: 600;
                                }
                            }
                        }

                        .hero__search__button {
                            flex-direction: column;


                            .ant-select {
                                width: 320px !important;

                                .search__input {
                                    width: 320px;
                                }
                            }

                            .serach_btn {
                                display: none;
                            }
                        }
                    }
                }

                .hero__top__down {
                    margin-top: 20px;
                    flex-direction: column;

                    .social__media {
                        flex-direction: row;
                        gap: 20px;
                        margin-top: 10px;
                        order: 2;

                        a {
                            color: $white-color;
                            font-size: 16px;
                        }
                    }

                    .hero__top__down__right {
                        order: 1;
                        gap: 20px;
                        padding: 20px;
                        flex-direction: column;

                        .small_cards_container {
                            position: relative;
                            width: 100%;
                            height: 150px;
                            border-radius: 10px;
                            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

                            .custom__card {
                                position: absolute;
                                width: 100%;
                                padding: 10px;
                                text-align: center;
                                left: 0;
                                opacity: 0;
                                animation: showHide 6s infinite linear;
                                animation-timing-function: ease-in-out;

                                &:nth-child(1) {
                                    animation-delay: 0s;
                                }

                                &:nth-child(2) {
                                    animation-delay: 2s;
                                }

                                &:nth-child(3) {
                                    animation-delay: 4s;
                                }

                                p {
                                    display: block !important;
                                }

                                h2 {
                                    height: 30px;
                                }
                            }
                        }

                        .big__card {
                            display: none;
                            width: 100%;

                            video {
                                border-radius: 10px;
                                width: 100% !important;
                                display: block;
                                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                            }
                        }
                    }
                }

                @keyframes showHide {

                    0%,
                    33.33% {
                        opacity: 1;
                    }

                    33.34%,
                    100% {
                        opacity: 0;
                    }
                }
            }
        }
    }
}



// Mobile Search Dropdown
.search__select__popup {
    .ant-select-item {
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 10px 10px;

        svg {
            font-size: 18px;
            color: $light-2-color;
            margin-right: 3px;
        }
    }
}




// Search Popup
.certain-category-search-popup {
    width: 500px;
    border-radius: 30px;

    @media (max-width: 1200px) {
        width: 320px;
    }

    @media (max-width: 700px) {
        width: 370px !important;
    }

    @media (max-width: 500px) {
        width: 355px !important;
    }

    @media (max-width: 400px) {
        width: 315px !important;
    }

    .ant-select-item {
        background: none !important;
    }

    .result-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 10px;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: $primary-color;
            margin: 0;
        }

        p {
            font-size: 16px;
            color: $gray-color;
            margin: 0;
        }
    }

    .empty {
        font-size: 20px;
        display: flex;
        align-items: center;
        color: $gray-color;

        p {
            font-size: 16px;
            margin-left: 10px;
        }
    }


    .result-row-activities {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 10px 5px;

        .left {
            width: 50px;
            height: 50px;
            border-radius: 5px;
            overflow: hidden;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .right {

            h3 {
                font-size: 18px;
                font-weight: 600;
                color: $primary-color;
                margin: 0;
            }

            p {
                font-size: 14px;
                color: $gray-color;
                margin: 0;
            }
        }
    }
}