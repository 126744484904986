@import '../../../../theme/variables.scss';

.tourPackage {

    @media (max-width: 1100px) {
        margin-top: 60px;
    }

    @media (max-width: 768px) {
        margin-top: 0px;
    }

    .hero__content_header {
        display: flex;
        gap: 50px;
        align-items: center;
        justify-content: space-between;

        .actions {
            display: flex;
            gap: 18px;

            .button {
                padding: 0;
                font-size: 30px;
            }

            @media (max-width: 768px) {
                align-items: center;

                .button {
                    font-size: 24px;
                }
            }

            .dark {
                color: $primary-color;

                &:hover {
                    color: $light-2-color;
                }

                &.active {
                    color: $light-1-color;
                }
            }
        }
    }

    .image_gallery {
        display: flex;
        gap: 20px;
        margin-top: 20px;
        height: 500px;
        position: relative;

        @media (max-width: 1366px) {
            height: 400px;
        }

        @media (max-width: 1024px) {
            height: 300px;
        }

        .total {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: rgba(0, 0, 0, 0.6);
            color: $white-color;
            padding: 5px 10px;
            border-radius: 5px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 10px;
            cursor: pointer;
        }

        .left {
            width: 70%;
        }

        .right {
            width: 30%;

            .images_container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                height: 100%;

                span {
                    height: 50%;

                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .more_images {
                display: none;
            }
        }

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 5px;
            margin-top: 10px;
            height: auto;

            .total {
                bottom: 5px;
                right: 5px;
                font-size: 12px;
            }

            img {
                border-radius: 5px;
            }

            .left {
                width: 100%;
            }

            .right {
                width: 100%;

                .images_container {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;

                    span {
                        height: 70px;
                        width: 100%;

                        img {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .tour_package_content_body {
        display: flex;
        justify-content: start !important;
        margin-top: 20px;
        gap: 20px;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 10px;
        }

        .content__container {
            max-width: 69%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media (max-width: 1280px) {
                max-width: 70%;
            }

            @media (max-width: 768px) {
                max-width: 100%;
            }

            section {
                border-radius: 10px;
                padding: 20px;
                background-color: #fff;
            }

            .sec__1 {
                display: flex;
                gap: 24px;
                flex-direction: column;

                .tags {
                    display: flex;
                    gap: 5px;
                    flex-wrap: wrap;

                    .ant-tag {
                        padding: 5px 10px;
                        background-color: transparent;
                        color: $light-2-color;
                        border-radius: 5px;
                        border: 1px solid $light-2-color;
                        font-size: 14px;
                    }

                    @media (max-width: 576px) {
                        gap: 3px;

                        .ant-tag {
                            font-size: 12px;
                            padding: 4px 10px;
                        }
                    }
                }

                .short_details {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .row_cus {
                        display: flex;
                        gap: 30px;

                        .col_cus {
                            display: flex;
                            gap: 10px;
                            align-items: center;

                            .title {
                                font-size: 16px;
                                color: $gray-color;
                            }

                            .value {
                                font-size: 16px;
                                color: $primary-color;
                            }
                        }

                        @media (max-width: 576px) {
                            flex-direction: column;
                            gap: 10px;

                            .col_cus {
                                .title {
                                    font-size: 14px;
                                }

                                .value {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                .about {
                    .quill {
                        margin-top: 10px;
                    }
                }

                .highlights {
                    .list {
                        all: unset;
                        margin-top: 15px;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        gap: 15px;

                        span {
                            display: flex;
                            gap: 10px;
                            align-items: center;

                            p {
                                margin: 0;
                                padding: 0;
                            }

                            .anticon {
                                color: $primary-color;
                                font-size: 14px;
                            }
                        }

                        @media (max-width: 576px) {
                            gap: 10px;

                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .sec__2 {
                .slider {
                    margin: 15px 0 20px 0;

                    .react-multiple-carousel__arrow {
                        z-index: 10 !important;
                    }

                    .custom-dot-list-style {
                        display: none;
                    }

                    .destination_card {
                        margin: 0 5px;
                        border: none;
                    }

                    .card_image {
                        height: 170px;
                        border-radius: 10px;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            border-radius: 10px;
                        }
                    }

                    .card_body {
                        padding: 0;
                    }

                    .card_title {
                        margin: 0;
                        margin-top: 10px;
                        font-size: 18px;
                        font-weight: 600;
                        color: $dark-color;
                        padding: 0;
                    }
                }

                .route {
                    margin-top: 15px;
                    width: 100%;
                    height: 400px;
                    background-color: rgba(226, 226, 226, 0.662);
                    border-radius: 10px;

                    iframe {
                        width: 100%;
                        height: 100%;
                        border: none;
                        border-radius: 10px;
                    }
                }
            }

            .sec__3 {
                .itinerary {
                    margin-top: 15px;

                    .itinerary_step {
                        .ant-steps-icon-dot {
                            background: none !important;
                            border: 2px solid $primary-color-light;
                            width: 15px;
                            height: 15px;
                        }

                        .ant-steps-item-tail {
                            top: 15px;
                            left: 3px;
                        }

                        .ant-steps-item-container {

                            .ant-steps-item-content {
                                background-color: $background-color;
                                margin-bottom: 10px;
                                border-radius: 10px;

                                .ant-steps-item-title {
                                    width: 100%;
                                    padding: 0 10px;
                                }

                                .ant-collapse-header {
                                    padding: 12px 16px 0 16px;

                                    .ant-collapse-header-text {
                                        span {
                                            display: flex;
                                            align-items: center;
                                            gap: 10px;

                                            h2 {
                                                font-size: 16px;
                                                margin: 0;
                                                font-weight: 700;
                                                color: $gray-color;
                                            }

                                            p {
                                                font-size: 16px;
                                                margin: 0;
                                                color: $primary-color;
                                                font-weight: 600;
                                            }

                                            @media (max-width: 768px) {
                                                h2 {
                                                    font-size: 14px;
                                                }

                                                p {
                                                    font-size: 14px;
                                                }
                                            }
                                        }
                                    }
                                }

                                .itinerary_collapse {
                                    h2 {
                                        font-size: 20px;
                                        font-weight: 700;
                                        color: $gray-color;
                                        margin: 0;
                                    }

                                    h3 {
                                        font-size: 18px;
                                        font-weight: 600;
                                        color: $primary-color;
                                        margin: 10px 0;
                                        cursor: pointer;

                                        &:hover {
                                            color: $light-1-color;
                                        }
                                    }

                                    .day_description {
                                        margin: 0;
                                    }

                                    h4 {
                                        font-size: 18px;
                                        font-weight: 600;
                                        color: $primary-color;
                                        margin: 0 0 10px 0;
                                    }

                                    @media (max-width: 768px) {
                                        h2 {
                                            font-size: 18px;
                                        }

                                        h3 {
                                            font-size: 16px;
                                        }

                                        h4 {
                                            font-size: 16px;
                                        }
                                    }

                                    .main__destination {
                                        display: flex;
                                        margin-top: 10px;
                                        gap: 20px;
                                        align-items: start;

                                        .left {
                                            order: 1;

                                            img {
                                                width: 300px;
                                                height: 160px;
                                                object-fit: cover;
                                                border-radius: 10px;
                                            }
                                        }

                                        .right {
                                            order: 2;

                                            .ql-editor {
                                                padding: 0;
                                                background-color: none;
                                                all: unset;

                                                p {
                                                    font-size: 14px;
                                                    line-height: 1.5;
                                                    color: $dark-color;

                                                    @media (max-width: 768px) {
                                                        font-size: 14px;
                                                    }
                                                }
                                            }
                                        }

                                        @media (max-width: 576px) {
                                            flex-direction: column;
                                            gap: 10px;

                                            .left {
                                                order: 2;

                                                img {
                                                    width: 100%;
                                                    height: 150px;
                                                }
                                            }

                                            .right {
                                                order: 1;
                                            }
                                        }
                                    }

                                    .experience {
                                        .note {
                                            margin-top: 10px;

                                            span {
                                                color: $red_orange_color;
                                                font-weight: 600;
                                            }
                                        }

                                        .itemss {
                                            margin-top: 15px;
                                            display: flex;
                                            flex-direction: column;
                                            gap: 10px;

                                            .item_iti_dis {
                                                display: flex;
                                                gap: 20px;

                                                .left {
                                                    order: 1;

                                                    img {
                                                        width: 300px;
                                                        height: 160px;
                                                        object-fit: cover;
                                                        border-radius: 10px;
                                                    }
                                                }

                                                .right {
                                                    order: 2;

                                                    p {
                                                        margin: 5px 0;
                                                    }

                                                    .ql-editor {
                                                        padding: 0;
                                                        background-color: none;
                                                        all: unset;

                                                        p {
                                                            font-size: 14px;
                                                            line-height: 1.5;
                                                            color: $dark-color;

                                                            @media (max-width: 768px) {
                                                                font-size: 14px;
                                                            }
                                                        }
                                                    }
                                                }

                                                @media (max-width: 576px) {
                                                    flex-direction: column;
                                                    gap: 10px;

                                                    .left {
                                                        order: 2;

                                                        img {
                                                            width: 100%;
                                                            height: 150px;
                                                        }
                                                    }

                                                    .right {
                                                        order: 1;

                                                        p {
                                                            margin: 5px 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .sec__4 {
                h3 {
                    margin-top: 15px;
                    font-size: 20px;
                    font-weight: 700;
                    color: $primary-color;
                }

                .list {
                    all: unset;
                    margin-top: 15px;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    justify-content: start;
                    align-items: start;

                    span {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        p {
                            margin: 0;
                            padding: 0;
                        }

                        .anticon {
                            color: $primary-color;
                            font-size: 14px;
                        }
                    }

                    .include {
                        .ql-container {
                            margin: 8px 0 0 30px;
                        }
                    }

                    @media (max-width: 576px) {
                        gap: 10px;

                        p {
                            font-size: 14px;
                        }
                    }
                }
            }

            .sec__5 {
                .cont {
                    margin-top: 15px;
                }

                .list {
                    margin-left: 30px;
                }

                .sub_charge {
                    margin-left: 30px;
                }

                .videos {
                    margin-top: 20px;
                    background-color: $primary-color;
                    border-radius: 10px;
                    overflow: hidden;

                    div {
                        height: 500px;
                        backdrop-filter: blur(20px);

                        @media (max-width: 1366px) {
                            height: 400px;
                        }

                        @media (max-width: 768px) {
                            height: 300px;
                        }

                        video {
                            height: 100%;
                            border-radius: 10px;
                            margin: auto;
                            width: 100%;
                        }
                    }
                }

                .header {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 20px;

                    p {
                        width: 70%;
                    }

                    .lazy-load-image-background {
                        width: 25%;

                        img {
                            width: 100%;
                            object-fit: cover;
                            border-radius: 10px;
                        }
                    }
                }

                @media (max-width: 768px) {
                    .videos {
                        div {
                            height: 200px;
                        }
                    }

                    .cont {
                        margin-top: 10px;
                        font-size: 14px;
                    }

                    .list {
                        margin-left: 10px;
                        font-size: 14px;
                    }

                    .sub_charge {
                        margin-left: 15px;
                        font-size: 14px;
                    }

                    .header {
                        flex-direction: column;
                        gap: 10px;
                        margin-bottom: 0px;

                        p {
                            width: 100%;
                        }

                        .lazy-load-image-background {
                            width: 60%;
                            margin: auto;
                        }
                    }
                }
            }

            .sec__6 {
                .note {
                    margin-top: 10px;

                    span {
                        color: $red_orange_color;
                        font-weight: 600;

                        @media (max-width: 576px) {
                            font-size: 14px;
                        }

                    }
                }

                .itemss {
                    margin-top: 15px;

                    .item_iti_dis {
                        display: flex;
                        gap: 20px;
                        align-items: center;

                        .left {
                            order: 1;

                            img {
                                width: 200px;
                                height: 100px;
                                object-fit: cover;
                                border-radius: 10px;
                            }
                        }

                        .right {
                            order: 2;

                            p {
                                margin: 5px 0;
                            }

                            h5 {
                                font-size: 16px;
                                font-weight: 700;
                                color: $primary-color;
                            }

                            h3 {
                                font-size: 18px;
                                font-weight: 600;
                                color: $primary-color;
                                margin: 0 0 10px 0;
                            }

                            .ql-editor {
                                padding: 0;
                                background-color: none;
                                all: unset;

                                p {
                                    font-size: 14px;
                                    line-height: 1.5;
                                    color: $dark-color;

                                    @media (max-width: 768px) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }

                        @media (max-width: 576px) {
                            flex-direction: column;
                            gap: 10px;
                            align-items: start;

                            .left {
                                order: 2;

                                img {
                                    width: 100%;
                                    height: 100px;
                                    object-fit: cover;
                                }
                            }

                            .right {
                                order: 1;

                                h5 {
                                    font-size: 14px;
                                }

                                h3 {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }

            .sec__7 {
                .enquire_form {
                    margin-top: 15px;

                    .ant-form {
                        flex: 1;

                        label {
                            color: #8D8D8D;
                            font-weight: 500;
                            font-size: 14px;
                        }

                        .ant-input {
                            border: none;
                            border-radius: 0;
                            border-bottom: 1px solid #8D8D8D;
                        }

                        .ant-input-outlined:focus {
                            border-bottom: 2px solid $light-1-color;
                            box-shadow: none;
                        }

                        .subject {
                            margin-top: 20px;
                            margin-bottom: 10px;

                            h3 {
                                font-size: 18px;
                                font-weight: 600;
                                margin-bottom: 20px;
                            }
                        }

                        .submit_btn {
                            display: flex;
                            align-items: center;
                            justify-content: end;
                            margin-bottom: 0 !important;
                        }

                        @media (max-width: 600px) {
                            .row {
                                display: flex;
                                flex-direction: column;
                            }

                            .subject {
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }

        .buy_now {
            width: 30%;
            height: 100%;
            border-radius: 10px;
            padding: 20px;
            background-color: #fff;
            display: flex;
            flex-direction: column;

            @media (max-width: 768px) {
                width: 100%;
            }

            .price {
                font-size: 26px;
                color: $primary-color;
                font-weight: 600;
                margin: 0 0 15px 0;

                span {
                    font-size: 16px;
                }
            }

            p {
                margin: 10px 0 15px 0;
            }

            .button_p {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 25px 20px;
                margin-bottom: 10px;
                font-weight: 600;

                svg {
                    font-size: 20px;
                }
            }

            .dark {
                color: $primary-color;
                background-color: $primary-color-light;

                &:hover {
                    color: $white-color;
                    background-color: $primary-color;
                }
            }

            .inquire {
                color: $light-2-color;
                border: 1px solid $light-2-color;

                &:hover {
                    color: $primary-color;
                    border: 1px solid $primary-color;
                }
            }

            .more {
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 1500px) {
                    flex-direction: column;
                    gap: 20px;
                }

                @media (max-width: 768px) {
                    flex-direction: row;
                    gap: 20px;
                }

                @media (max-width: 576px) {
                    flex-direction: column;
                    gap: 20px;
                }

                .button {
                    font-size: 16px;

                    svg {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .blogs {
        margin-top: 30px;

        .package__card {
            min-height: 100%;
            width: 100%;

            .author {
                display: flex;
                align-items: center;
                gap: 10px;

                .author_image {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid $primary-color-light;
                    padding: 2px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                .author_name {
                    h3 {
                        margin: 0;
                        font-size: 18px;
                        color: $dark-color;
                    }

                    p {
                        margin: 0;
                        font-size: 12px;
                        color: $gray-color;
                    }
                }
            }
        }
    }
}