@import '../../../theme/variables.scss';

.privacy {
    .top_padding_container {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-bottom: 20px;

        @media (max-width: 700px) {
            flex-direction: column;

            .header_1 {
                margin-bottom: 0;
            }

            p {
                font-size: 14px;
                margin-top: 5px;
            }
        }
    }

    .mobile_nav {
        display: none;

        @media (max-width: 700px) {
            display: block;
            margin-bottom: 20px;
        }
    }

    .body {
        display: flex;
        gap: 45px;

        .terms__nav {
            width: 20%;
            height: 100%;
            border-left: 1px solid $light-gray-color;
            border-right: 1px solid $light-gray-color;
            border-top: 1px solid $light-gray-color;
            border-radius: 10px;
            display: flex;
            flex-direction: column;

            .terms__nav__container__items__item {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 20px 20px;
                border-bottom: 1px solid $light-gray-color;
                font-size: 16px;
                color: $dark-color;
                cursor: pointer;
                font-weight: 500;
                transition: all 0.3s ease;

                .anticon {
                    font-size: 20px;
                }

                &:hover {
                    color: $light-2-color;
                }
            }

            .active {
                color: $light_5_color;
            }

            //  last item
            .terms__nav__container__items__item:last-child {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            @media (max-width: 700px) {
                display: none;
            }
        }

        .terms {
            width: 75%;

            @media (max-width: 700px) {
                width: 100%;
            }

            .sec {
                margin-top: 30px;
            }

            .heder_2 {
                margin-bottom: 15px;
            }

            .header_4 {
                margin-bottom: 15px;
            }

            .paragraph {
                margin-bottom: 15px;
                line-height: 1.6;
            }

            ul,
            ol {
                padding-left: 50px;

                li {
                    margin-bottom: 10px;
                    color: $primary-color;

                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.home__nav__drawer_body {
    .terms__nav_drawe {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;

        .terms__nav__container__items__item_drawer {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 16px;
            color: $dark-color;
            cursor: pointer;
            font-weight: 500;
            transition: all 0.3s ease;

            .anticon {
                font-size: 20px;
            }

            &:hover {
                color: $light-2-color;
            }
        }
    }
}