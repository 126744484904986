@import '../../../../../theme/variables.scss';

.request_call {
    padding-bottom: 100px !important;

    @media (max-width: 768px) {
        padding-bottom: 50px !important;
    }


    .inner_contect {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        height: 550px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        overflow: hidden;

        @media (max-width: 768px) {
            height: 500px;
        }

        @media (max-width: 600px) {
            flex-direction: column;
            height: auto;
            gap: 30px;
        }

        .left {
            width: 40%;
            color: $white-color;
            display: flex;
            flex-direction: column;
            padding-left: 30px;

            div {
                margin-top: 50px;
            }

            h3 {
                font-size: 18px;
                margin-bottom: 10px;
                width: 80%;
                font-weight: 400;
                margin-top: 10px;
                margin-bottom: 80px;
                line-height: 160%;
            }

            section {
                list-style: none;
                padding: 0;
                margin: 0;

                p {
                    display: flex;
                    align-items: center;
                    margin-bottom: 50px;
                    width: 80%;
                    font-size: 16px;

                    a {
                        color: $white-color;
                        text-decoration: none;
                        transition: all 0.3s;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .anticon {
                        margin-right: 10px;
                        font-size: 20px;
                    }
                }
            }

            @media (max-width: 1280px) {
                h1 {
                    font-size: 35px;
                }
            }

            @media (max-width: 768px) {
                div {
                    align-items: center;
                }

                h1 {
                    font-size: 30px;
                }

                h3 {
                    font-size: 16px;
                    margin-bottom: 60px;
                }

                section {
                    p {
                        font-size: 14px;
                        margin-bottom: 30px;
                    }
                }
            }

            @media (max-width: 600px) {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                height: 320px;
                background-position: 0px -40px !important;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;

                div {
                    margin-top: 30px;
                }

                h1 {
                    font-size: 25px;
                }

                h3 {
                    font-size: 14px;
                    margin-bottom: 40px;
                    width: 100%;
                }

                section {
                    p {
                        font-size: 12px;
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .right {
            width: 60%;
            display: flex;
            align-items: center;
            padding: 0 50px 0 0;

            .ant-form {
                flex: 1;

                label {
                    color: #8D8D8D;
                    font-weight: 500;
                    font-size: 14px;
                }

                .ant-input {
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid #8D8D8D;
                }

                .ant-input-outlined:focus {
                    border-bottom: 2px solid $light-1-color;
                    box-shadow: none;
                }

                .subject {
                    margin-top: 20px;
                    margin-bottom: 10px;

                    h3 {
                        font-size: 18px;
                        font-weight: 600;
                        margin-bottom: 20px;
                    }
                }

                .submit_btn {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    margin-bottom: 0 !important;
                }

                @media (max-width: 600px) {
                    .row {
                        display: flex;
                        flex-direction: column;
                    }

                    .subject {
                        margin-top: 10px;
                    }
                }
            }

            @media (max-width: 600px) {
                width: 100%;
                padding: 0 20px 25px 20px;
                height: auto;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;

                .ant-form {
                    label {
                        font-size: 15px;
                    }

                    .ant-input {
                        border-bottom: 1px solid #8D8D8D;
                    }

                    .subject {
                        h3 {
                            font-size: 16px;
                        }
                    }

                    .ant-radio-group {
                        display: flex;
                        gap: 10px;
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }
}