// cookie-consent.scss
.cookie-consent {
    $primary-blue: #67DCD3;
    $orange-bg: #fff7ed;
    $orange-icon: #c7862a;
    $text-gray: #4b5563;

    &-wrapper {
        position: fixed;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
        z-index: 50;
        display: flex;
        justify-content: center;
    }

    &-card {
        background-color: white;
        border-radius: 0.75rem;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
        border: 1px solid rgba(0, 0, 0, 0.05);
        max-width: 38rem;
        width: 100%;
        padding: 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;


        @media (max-width: 640px) {
            gap: 0;
        }
    }

    &-icon {
        background-color: #{$orange-bg};
        padding: 0.75rem;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: #{$orange-icon};
            width: 2rem;
            height: 2rem;
        }

        @media (max-width: 640px) {
            margin-bottom: 0;
        }
    }

    &-content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            color: #{$text-gray};
            font-size: 0.875rem;
            margin-bottom: 0.75rem;
        }

        a {
            color: #50A8B8;
            font-weight: 400;
            text-decoration: none;
            transition: color 0.2s;

            &:hover {
                color: darken($primary-blue, 10%);
            }
        }

        @media (max-width: 640px) {
            flex-direction: column;
            text-align: center;
        }
    }

    &-button {
        width: 100%;
        background-color: #{$primary-blue};
        color: #063D67;
        font-weight: 600;
        padding: 0.5rem;
        max-width: 10rem;
        border: none;
        border-radius: 0.375rem;
        transition: background-color 0.2s;

        &:hover {
            background-color: darken($primary-blue, 10%);
        }
    }

    @media (max-width: 640px) {
        &-card {
            flex-direction: column;
            text-align: center;
        }

        &-icon {
            margin-bottom: 1rem;
        }
    }
}