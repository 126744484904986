@import '../../../theme/variables.scss';

.support {
    .top_padding_container {

        .header_1 {
            text-align: center;
        }
    }

    .support__cards {
        display: flex;
        flex-direction: column;
        margin-top: 100px;
        gap: 20px;
        justify-content: center;
        align-items: center;

        .card_row {
            display: flex;
            gap: 20px;

            @media (max-width: 600px) {
                flex-direction: column;
            }

            .support_card {
                max-width: 400px;
                width: 100%;
                border-radius: 15px;
                background-color: #fff;
                padding: 20px;
                transition: all 0.3s ease;
                cursor: pointer;

                &:hover {
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
                }

                .icon {
                    svg {
                        font-size: 40px;
                        color: $primary-color;
                    }
                }

                h2 {
                    font-size: 34px;
                    font-weight: 500;
                    color: $primary-color;
                    margin: 5px 0;
                }

                p {
                    font-size: 16px;
                    color: $dark-color;
                    line-height: 1.6;

                    a {
                        color: $light-2-color;
                        text-decoration: none;
                    }
                }

                @media (max-width: 600px) {
                    max-width: 100%;

                    .icon {
                        svg {
                            font-size: 30px;
                        }
                    }

                    h2 {
                        font-size: 25px;
                    }
                }
            }
        }
    }
}