@import '../../../theme/variables.scss';

.onboarding__page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
        width: 200px;
        padding: 20px 0 0 30px;

        img {
            width: 100%;
            display: block;
        }

        @media screen and (max-width: 768px) {
            width: 140px;
            padding: 20px 0 0 20px;
            margin: 0 auto;
        }
    }

    .onboarding__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px 30px;

        .middle {
            margin: 50px 0;

            @media screen and (max-width: 768px) {
                margin: 30px 0;
            }
        }

        h1 {
            font-size: 32px;
            font-weight: 600;
            color: $primary-color;
            margin-bottom: 20px;
        }

        h3 {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 80px;
        }

        .ant-checkbox-wrapper {
            color: $white-color;
            background-color: $light-2-color;
            font-size: 15px;
            padding: 10px 15px;
            border-radius: 100px;
            font-weight: 600;
            transition: all 0.3s ease;

            &:hover {
                transform: scale(1.05);
            }

            .ant-checkbox {
                display: none;
            }
        }

        .ant-checkbox-wrapper-checked {
            background-color: $primary-color;
            color: $white-color;
        }

        .warning {
            margin-top: 20px;
            color: $danger_color;
        }

        .buttons {
            margin-top: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .ant-btn {
                font-size: 16px;
                font-weight: 500;
                color: $primary-color;
                display: flex;
                align-items: center;
                height: 40px;
                border-radius: 15px;

                &:hover {
                    transform: scale(1.05);
                }
            }

            .prev {
                border: 1px solid $primary-color-light;
            }

            .def {
                background-color: $primary-color-light;
                color: $primary-color;
                width: 100px;
            }
        }

        @media screen and (max-width: 908px) {
            h1 {
                font-size: 28px;
                margin-bottom: 15px;
            }

            h3 {
                font-size: 16px;
                margin-bottom: 50px;
            }

            .buttons {
                margin-top: 50px;
            }

            .ant-checkbox-wrapper {
                font-size: 13px;
                padding: 8px 12px;
            }
        }

        @media screen and (max-width: 768px) {
            h1 {
                font-size: 24px;
                margin-bottom: 10px;
            }

            h3 {
                margin-top: 20px;
                font-size: 14px;
                margin-bottom: 40px;
                line-height: 160%;
            }

            .buttons {
                margin-top: 40px;
            }

            .ant-checkbox-group {
                margin: auto;
                display: flex;
                gap: 15px;
                align-items: center;
                justify-content: center;
            }

            .ant-progress-outer {
                width: 100% !important;
            }
        }

        @media screen and (max-width: 480px) {
            h1 {
                font-size: 18px;
                margin-bottom: 5px;
            }

            h3 {
                font-size: 16px;
                margin-bottom: 30px;
            }

            .buttons {
                margin-top: 30px;
            }

            .ant-checkbox-group {
                margin: auto;
                display: flex;
                flex-direction: column;
                gap: 15px;
                align-items: center;
                justify-content: center;
            }

            .ant-checkbox-wrapper {
                font-size: 15px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 155px;
                height: 40px;
            }
        }

        .ant-progress-outer {
            margin: 0 auto;
            display: flex;
            flex-direction: column-reverse;
            align-items: start;
            justify-content: start;
            gap: 10px;

            .ant-progress-text {
                font-size: 15px;
                color: $medium-gray-color;
                font-weight: 500;
            }
        }
    }

    .bottom {
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 20px 30px;
        margin-bottom: 20px;

        .ant-btn {
            font-size: 20px;
            font-weight: 500;
            color: $primary-color;
            box-shadow: none;
            border: none;
            padding: 30px;
            border-radius: 50px;
            display: flex;
            align-items: center;

            svg {
                font-size: 20px;
            }

            &:hover {
                transform: scale(1.05);
            }
        }
    }
}