@import '../../../../../theme/variables.scss';

.custom_tour_destination_select {


    .custom_tour_start_planing__form {
        .form_item_label {
            width: 130px;
            text-align: start;
        }

        .selected_tags {
            margin: 10px 0 20px 0;
            border: 1px dashed $medium-gray-color;
            border-radius: 20px;
            padding: 20px;

            h1 {
                font-size: 14px;
                font-weight: 500;
                color: $gray-color;
            }

            .tags {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;

                .tag {
                    border: 1px solid $light-1-color;
                    color: $light-1-color;
                    padding: 8px 15px;
                    border-radius: 10px;
                }

                p {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 400;
                    color: $medium-gray-color;
                }
            }
        }
    }

    .filter__btn {
        display: none;
        margin-bottom: 10px;

        @media (max-width: 900px) {
            display: block;
        }
    }

    .desctions_wrapper {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .filter__section {
            width: 30%;
            display: flex;
            flex-direction: column;
            background-color: $background-color;
            height: 100%;
            padding: 20px;
            border-radius: 20px;

            @media (max-width: 1280px) {
                width: 25%;
            }

            @media (max-width: 900px) {
                display: none;
            }

            .filter__group {
                .header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h1 {
                        font-size: 20px;
                        font-weight: 600;
                        color: $primary-color;
                    }
                }

                .radios {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }
    }

    .destinations_selector {
        width: 70%;

        @media (max-width: 1280px) {
            width: 75%;
        }

        @media (max-width: 900px) {
            width: 100%;
        }

        .destinations_row {
            row-gap: 20px;

            .ant-btn {
                font-size: 20px;
                width: 35px;
                height: 35px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary-color;
            }
        }

        .col {
            position: relative;

            .info__button {
                position: absolute;
                top: 10px;
                right: 20px;
                z-index: 2;
            }
        }

        .destination__card {
            width: 100%;
            max-width: 100%;
            padding: 15px;
            position: relative;

            &:hover {
                transform: scale(1.03) !important;
            }

            .background__overlay {
                z-index: 0;
            }

            .destination__card__content {
                z-index: 1;
                position: relative;
            }

            .select {
                position: absolute;
                bottom: 20px;
                right: 20px;
                z-index: 2;
                font-size: 16px;
                display: flex;
                font-weight: 600;
                align-items: center;
                justify-content: center;
                padding: 10px;
                color: $primary-color;
                border-radius: 10px;
                background-color: #ffffffcb;
                backdrop-filter: blur(5px);
            }

            .selected {
                position: absolute;
                bottom: 20px;
                right: 20px;
                z-index: 2;
                font-size: 16px;
                display: none;
                align-items: center;
                justify-content: center;
                gap: 5px;
                background-color: $primary-color-light;
                color: $primary-color;
                font-weight: 600;
                padding: 10px;
                border-radius: 10px;
            }
        }

        .destination__card:has(.ant-checkbox-checked) {
            .selected {
                display: flex;
            }

            .select {
                display: none;
            }
        }

        .ant-checkbox {
            display: none;
        }

        .destination__card_loading {
            width: 100%;
            max-width: 100%;
        }
    }
}

.custom_tour_filter {
    .filter__section {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-radius: 20px;

        .filter__group {
            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h1 {
                    font-size: 20px;
                    font-weight: 600;
                    color: $primary-color;
                }
            }

            .radios {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
    }
}