@import '../../../../theme/variables.scss';

.about_section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 600px) {
        flex-direction: column;
    }

    .left {
        width: 60%;

        .ant-tabs-top>.ant-tabs-nav::before,
        :where(.css-dev-only-do-not-override-4po8uv).ant-tabs-top>div>.ant-tabs-nav::before {
            display: none;
        }

        .ant-tabs-ink-bar {
            background-color: $dark-gray-color;
            height: 2.5px;
            transform: translateY(-8px) translateX(-50%) !important;
        }

        .ant-tabs-nav-list {
            .ant-tabs-tab {
                .ant-tabs-tab-btn {
                    color: $dark-gray-color;
                    font-size: 16px;
                    font-weight: 600;

                    @media (max-width: 1200px) {
                        font-size: 12px;
                        color: $dark-gray-color;
                        font-weight: 600;
                    }
                }
            }
        }

        .ant-tabs-nav-wrap::after {
            display: none;
        }

        .ant-tabs-nav-wrap::before {
            display: none;
        }

        h2 {
            font-size: 2.5rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 160%;
        }

        .btns {
            display: flex;
            gap: 30px;
            margin-top: 20px;
            align-items: center;

            .socials {
                display: flex;
                align-items: center;
                gap: 25px;

                .anticon {
                    font-size: 25px;
                    color: $primary-color;
                    transition: all 0.3s;

                    &:hover {
                        color: $primary-color-light;
                        transform: scale(1.1);
                    }
                }
            }
        }

        @media (max-width: 1024px) {
            p {
                font-size: 16px;
            }
        }

        @media (max-width: 768px) {
            h2 {
                font-size: 2rem;
            }

            p {
                font-size: 14px;
            }

            .btns {
                gap: 10px;

                .socials {
                    gap: 15px;

                    .anticon {
                        font-size: 20px;
                    }
                }
            }
        }

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    .right {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 300px;
        }

        @media (max-width: 768px) {
            img {
                width: 250px;
            }
        }

        @media (max-width: 600px) {
            display: none;
        }
    }
}