@import '../../../../theme/variables.scss';

.section__all__destinations {

    .ant-tabs-top>.ant-tabs-nav::before,
    :where(.css-dev-only-do-not-override-4po8uv).ant-tabs-top>div>.ant-tabs-nav::before {
        display: none;
    }

    .ant-tabs-ink-bar {
        background-color: $dark-gray-color;
        height: 2.5px;
        transform: translateY(-8px) translateX(-50%) !important;
    }

    .ant-tabs-nav-list {
        .ant-tabs-tab {
            .ant-tabs-tab-btn {
                color: $dark-gray-color;
                font-size: 16px;
                font-weight: 600;

                @media (max-width: 1200px) {
                    font-size: 12px;
                    color: $dark-gray-color;
                    font-weight: 600;
                }
            }
        }
    }

    .ant-tabs-nav-wrap::after {
        display: none;
    }

    .ant-tabs-nav-wrap::before {
        display: none;
    }


    .load_more_btn {
        margin: 20px auto 0 auto;
    }
}