@import '../../../../../../theme/variables.scss';

.service_wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    flex-direction: column;
    gap: 10px;

    .service {
        display: flex;
        gap: 10px;
        align-items: start;

        .anticon {
            color: $primary-color-light;
            margin-top: 6px;
        }
    }
}

.sim_services {
    .note {
        margin-top: 10px !important;
    }

    .red {
        color: $red_orange_color;
    }

    a {
        color: $light-2-color;
        text-decoration: none;
    }

    .confirmation_form {
        margin-top: 15px;
    }

    .travellers {
        margin-top: 10px;
        width: 100%;

        .ant-divider {
            margin: 0;
        }

        h3 {
            font-size: 20px;
            font-weight: 600;
            color: $primary-color;
        }


        .traveller-details {
            margin-bottom: 10px;

            h4 {
                font-size: 16px;
                font-weight: 600;
                color: $gray-color;
            }

            p {
                font-size: 14px;
                color: $primary-color;
                line-height: 1.6;
            }
        }
    }
}