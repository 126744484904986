@import '../../../../theme/variables.scss';

.dashboard__Home {
    display: flex;
    min-height: 80vh;
    gap: 20px;

    .mobile_nav {
        display: none;
    }

    .dash_home_nav {
        width: 25%;
        height: 100%;
        border-left: 1px solid $light-gray-color;
        border-right: 1px solid $light-gray-color;
        border-top: 1px solid $light-gray-color;
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        .dash_home_nav__container__items__item {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 30px 20px;
            border-bottom: 1px solid $light-gray-color;
            font-size: 16px;
            color: $dark-color;
            cursor: pointer;
            font-weight: 500;
            transition: all 0.3s ease;

            .anticon {
                font-size: 20px;
            }

            &:hover {
                color: $light-2-color;
            }
        }

        .active {
            color: $light_5_color;
        }

        //  last item
        .dash_home_nav__container__items__item:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    .dash_home_content {
        width: 70%;
        padding: 0 10px;
    }

    @media (max-width:600px) {
        flex-direction: column;

        .mobile_nav {
            display: block;
        }

        .dash_home_nav {
            display: none;
        }

        .dash_home_content {
            width: 100%;
        }
    }
}

.home__nav__drawer_body {
    .ant-drawer-content-wrapper {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        overflow: hidden;

        .home__nav__drawer {
            .drawer__content_home_nav {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 20px;

                .drawer__content__items {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 10px 0;
                    font-size: 16px;
                    color: $dark-color;
                    cursor: pointer;
                    font-weight: 500;
                    transition: all 0.3s ease;

                    .anticon {
                        font-size: 20px;
                    }

                    &:hover {
                        color: $light-2-color;
                    }
                }

                .close {
                    margin-top: 20px;
                    color: $gray-color;
                }
            }
        }
    }
}