@import '../../../theme/variables.scss';

.dashboard {

    h1,
    p {
        margin: 0;
    }

    .ant-layout {
        gap: 5px;

        .inner_layout {
            background-color: $background-color;

            .dashboard__body {
                background-color: #fff;
                padding: 20px;
            }
        }
    }
}