@import '../../../../../../../theme/variables.scss';

.booking_payments {
    .payment_empty {
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        padding: 50px 20px;
    }

    .payment_splip_submit {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 0;
        width: 100%;

        .ant-upload-wrapper {
            width: 100%;
        }

        .payment_due_date {
            display: flex;
            gap: 10px;
            align-items: center;
            margin: 10px 0 0 0;
            font-size: 18px;
            color: $primary-color;
        }
    }

    .bank_details_container {
        .bank_details {
            font-size: 16px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            color: $primary-color;
        }
    }

    .instalment_plan_container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .instalment_plan {
            background-color: #fff;
            padding: 15px 20px;
            border-radius: 10px;

            h5 {
                margin: 0;
            }

            .details_container {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                margin-top: 5px;
                font-size: 16px;
                color: $primary-color;

                .action {
                    display: flex;
                    gap: 5px;
                }
            }
        }
    }
}


.instalment_pay_drawer {
    .instalment_submit {
        h4 {
            margin-bottom: 10px;
        }

        h5 {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }
}