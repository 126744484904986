@import '../../../../theme/variables.scss';

.custom_tour_plan_main {
    .steps_container {
        margin-top: 100px;
        background-color: #ffffffb0;
        backdrop-filter: blur(5px);
        border-radius: 20px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: start;

        @media (max-width: 900px) {
            margin-top: 50px;
        }
    }

    .content_body_main {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        margin-top: 100px;

        @media (max-width: 900px) {
            margin-top: 50px;
        }
    }

    .main_para {
        margin-top: 10px;
    }

    section {
        background-color: #ffffffb0;
        padding: 20px;
        border-radius: 20px;
        margin-bottom: 20px;

        .button_wrapper {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
        }
    }

}