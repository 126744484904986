@import '../../../../../../theme/variables.scss';

.travelers_wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .traveler {
        width: 100%;
        background-color: $background-color;
        padding: 15px;
        border-radius: 10px;

        h4 {
            font-size: 16px;
            color: $primary-color;
            font-weight: 600;
        }
    }
}

.sim_services {
    .note {
        margin-top: 10px !important;
    }

    .red {
        color: $red_orange_color;
    }

    .service_wrapper {
        width: 100%;
    }
}

.booking_form_customer_data {
    all: unset;
    width: 100%;
    display: flex;
    gap: 20px;

    @media (max-width: 900px) {
        flex-direction: column;
    }

    .booking_pricing {
        height: 100%;
    }
}