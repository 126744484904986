@import '../../../../theme/variables.scss';

.evenet_plan {
    .content_body {
        margin: 20px 0 30px 0;

        p {
            font-size: 16px;
            margin: 0;
            color: $primary-color;
            line-height: 1.5;
        }
    }

    .content_body_main {
        background-color: $background-color !important;
        min-height: 100vh;
    }

    .support__container {
        .quill {
            border: none;

            .ql-toolbar {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            .ql-container {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                min-height: 200px;
                padding: 20px;
            }
        }
    }

    .header_3 {
        margin-bottom: 10px;
    }

    .radio_group_event_type {
        display: flex;
        flex-wrap: wrap;

        .ant-radio-wrapper:has(.ant-radio-checked) {
            background-color: $light-3-color;

            &:hover {
                background-color: $light-3-color;
            }
        }

        .ant-radio-wrapper {
            background-color: #fff;
            padding: 8px;
            margin-bottom: 10px;
            border-radius: 10px;
            transition: all 0.3s ease;

            &:hover {
                background-color: #e2e2e2;
            }

            .ant-radio {
                display: none;
            }

            .flex {
                display: flex;
                flex-direction: column;
                width: 300px;
                padding-top: 10px;

                @media (max-width: 1440px) {
                    width: 240px;
                }

                @media (max-width: 1366px) {
                    width: 220px;
                }

                @media (max-width: 1280px) {
                    width: 200px;
                }

                @media (max-width: 1024px) {
                    width: 260px;
                }

                @media (max-width: 768px) {
                    width: 100%;
                }

                h5 {
                    font-size: 18px;
                    margin: 10px 0 5px 0;
                    color: $dark-color;
                    font-weight: 600;
                }

                p {
                    font-size: 14px;
                    margin: 0;
                    color: $dark-color;
                }

                .image {
                    width: 100%;
                    height: 200px;
                    overflow: hidden;
                    display: flex; // Ensure the container uses flexbox to align items

                    img {
                        width: 100%;
                        height: 100%; // Ensure the image takes up the full height of its container
                        object-fit: cover;
                        border-radius: 10px;
                    }
                }
            }
        }
    }

    .custom_tour_start_planing__form {

        .form_item_label {
            width: 130px;
            text-align: start;
        }

        .fav_selector {
            margin: 0;
            margin-bottom: 10px !important;

            .icon-wrapper {
                position: relative;
                padding: 0 30px;
            }
        }
    }

    .room_selector {
        .radio_group {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 10px 0 0 0;

            .room_type {
                background-color: $background-color;
                padding: 10px;
                border-radius: 10px;

                h5 {
                    font-size: 18px;
                    font-weight: 600;
                    color: $primary-color;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: $gray-color;
                }
            }
        }
    }

    section {
        background-color: #fff;
        padding: 20px;
        border-radius: 20px;
        margin-bottom: 20px;

        .button_wrapper {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
        }
    }

    .room_selector {
        .radio_group_vehicle {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 10px 0 0 0;

            .room_type {
                background-color: $background-color;
                padding: 10px;
                border-radius: 10px;

                .flex {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;

                    @media (max-width: 600px) {
                        flex-direction: column;
                        gap: 10px;
                    }

                    .image {
                        width: 200px;

                        .lazy-load-image-background {
                            width: 200px;

                            img {
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }

                h5 {
                    font-size: 18px;
                    font-weight: 600;
                    color: $primary-color;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: $gray-color;
                }
            }
        }
    }

    .content_body_custom_event {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 2px;
        color: $dark-color;
        width: 70%;

        @media (max-width: 1280px) {
            width: 80%;
        }

        @media (max-width: 768px) {
            width: 100%;
        }
    }
}