@import '../../../../../theme/variables.scss';

.custom_tour_start_planing {
    .padding_wrapper {
        width: 50%;

        @media (max-width: 1366px) {
            width: 70%;
        }

        @media (max-width: 900px) {
            width: 100%;
        }
    }

    .custom_tour_start_planing__form {

        .form_item_label {
            width: 130px;
            text-align: start;
        }

        .fav_selector {
            margin: 0;
            margin-bottom: 10px !important;

            .icon-wrapper {
                position: relative;
                padding: 0 30px;
            }
        }
    }

    .ant-slider-track {
        background-color: $primary-color-light;
    }

    .date_selector {
        .heder_2 {
            margin-bottom: 15px;
        }
    }

    .qty_selector {
        .wrapper {
            margin-top: 20px;
        }

        .quantityItem {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                display: flex;
                align-items: center;
                gap: 10px;

                h3 {
                    font-size: 20px;
                    font-weight: 600;
                    color: $primary-color;
                }

                h4 {
                    font-size: 18px;
                    font-weight: 400;
                    color: $gray-color;
                }
            }
        }
    }

    .room_selector {
        .radio_group {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 10px 0 0 0;

            .room_type {
                background-color: $background-color;
                padding: 10px;
                border-radius: 10px;

                h5 {
                    font-size: 18px;
                    font-weight: 600;
                    color: $primary-color;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: $gray-color;
                }
            }
        }
    }
}